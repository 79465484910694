import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import crossHair from "../assets/images/cursor-crosshairs.svg";
import cursorMove from "../assets/images/cursor-move.svg";
import adjView from "../assets/icons/icon-viewPosition-dark.svg";
import pointMarkerTest from "../assets/images/pointMarker-test.svg";
import * as Constants from "../constants";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import {
  // setFloorplanmarkers,
  // setCameramarkers,
  setFloorplanPoints,
  setCameraPoints,
  setFloorplanTestMarker,
  setCameraTestMarker,
  setFloorplanDimensions,
  setCameraSceneDiemsions,
  setCorrespondingCameraPointId,
  setCorrespondingFloorplanPointId,
} from "./../actions/imageData";
import {
  // setSelectedShapeToDelete,
  // SetImageMappingLoading,
  // setSelectedShape,
  setUpdateCoordinates,
  hideAddTestPoint,
} from "./../actions/imageOptions";
import { CanvasWithImage } from "./canvasWithImage";

const CustomReactPictureAnnotation = (props) => {
  const dispatch = useDispatch();
  // INITIALIZE THE CANVAS REF
  const canvasRef = useRef(null);
  // REFS FOR STORING CANVAS RELATED INFORMATION
  const isDragging = useRef(false);
  const isDraggedByMiddleButton = useRef(false)
  const lastXPos = useRef(null);
  const lastYPos = useRef(null);
  const canvasImage = useRef(null);
  const zoomRef = useRef(1);
  const markersRef = useRef([]);
  const testPointsRef = useRef([]);
  // ref for storing drag initial state
  const markerInitPosition = useRef(null);

  // STATES FOR STORING FLOORPLAN AND CAMERA POINTS
  const floorplanPoints = useSelector(
    (state) => state.imageDataReducer.floorplanPoints
  );
  const cameraPoints = useSelector(
    (state) => state.imageDataReducer.cameraPoints
  );
  const areCoordinatesUpdated = useSelector(
    (state) => state.imageOptionsReducer.updateCoordinates
  ); 
  const addPointToggle = useSelector(
    (state) => state.imageOptionsReducer.addPointToggle
  );
  const selectAndMovePointToggle = useSelector(
    (state) => state.imageOptionsReducer.selectAndMovePointToggle
  );
  let currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const cameraImageUrls = useSelector(
    (state) => state.imageDataReducer.cameraImageUrls
  );
  const homography = useSelector((state) => state.imageDataReducer.homography);
  const plotGridLines = useSelector(
    (state) => state.imageOptionsReducer.plotGridLines
  );
  const addTestPointToggle = useSelector(
    (state) => state.imageOptionsReducer.addTestPointToggle
  );
  const editCameraPositionToggle = useSelector(
    (state) => state.imageOptionsReducer.editCameraPostionToggle
  );

  const invertFloorplanColors = useSelector(
    (state) => state.imageOptionsReducer.invertFloorplanColors
  );
  const storePoints = useSelector(
    (state) => state.imageOptionsReducer.storePoints
  );
  const deletePoint = useSelector(
    (state) => state.imageOptionsReducer.deletePoint
  );
  const correspondingCamerPointId = useSelector(
    (state) => state.imageDataReducer.correspondingCameraPointId
  );
  const correspondingFloorplanPointId = useSelector(
    (state) => state.imageDataReducer.correspondingFloorplanPointId
  );
  const cameraPointsAvg = useSelector(
    (state) => state.imageDataReducer.cameraPointsAvg
  );
  // adjustViewPositionToggle IS ALSO PASSED THROUGH PROPS. SO IT MAKES NO SENSE FOR THE SELECTOR TO BE HERE
  // const adjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.adjustViewPositionToggle);
  const floorPlanImage = useSelector(
    (state) => state.imageDataReducer.imageUrls
  );

  const cameraImg = cameraImageUrls[currentCameraIndex];
  const uploadedImage = new Image();
  uploadedImage.src = props.id === "Canvas" ? floorPlanImage : cameraImg;
  const adjustViewPositionToggle = props.adjustViewPositionToggle;
  const markerImg = props.markerImg;

  // BAD APPROACH, THE CONDITION BELOW IS DIRECTLY TRYING TO REASSIGN A STATE VARIABLE.
  if (currentCameraIndex === undefined) currentCameraIndex = 0;
  const zoomDelta = (canvas, delta, x, y, maxZoom = 50, minZoom = 0.1) => {
    // SET minZoom TO ABOUT 0.1 IF ZOOMING OUT IS TO BE ALLOWED BEYOND THE INITIAL SIZE
    // WITH minZoom SET TO 1, THE CANVAS WILL NOT ZOOM OUT OF THE INITIAL SIZE
    zoomRef.current = canvas.getZoom();
    let zoom = zoomRef.current;
    zoom *= 0.999 ** delta;
    // LIMIT THE ZOOM BETWEEN MAX AND MIN VALUES
    zoom = Math.min(zoom, maxZoom);
    zoom = Math.max(zoom, minZoom);
    zoomRef.current = zoom;
    const point = {
      x,
      y,
    };
    canvas.zoomToPoint(point, zoom);
  };

  const calculateScaleFactor = (view, imageDimension, canvasDimension) => {
    if (view === "Canvas") {
      return imageDimension / canvasDimension;
    } else {
      if (cameraPointsAvg.yFactor > 1) {
        return imageDimension / canvasDimension;
      } else {
        return 1 / canvasDimension;
      }
    }
  };

  // USEEFFECT FOR UPDATING COORDINATES ON CANVAS OBJ
  useEffect(() => {
    if (!areCoordinatesUpdated) return;
    if (props.id === "Canvas") {
      floorplanPoints.forEach((point, index) => {
        // EXCLUDE FIRST OBJECT WHICH IS THE IMAGE ADDED ON CANVAS
        const marker = canvasRef.current.getObjects()[index + 1];
        // const pointer = {x: marker.x, y: marker.y};
        const dx = point.x - marker.x;
        const dy = point.y - marker.y;
        marker.set({
          initialLeft: point.x,
          initialTop: point.y,
          left: marker.left + dx,
          top: marker.top + dy,
          selectable: false,
        });
      });
    } else {
      cameraPoints.forEach((point, index) => {
        // EXCLUDE FIRST OBJECT WHICH IS THE IMAGE ADDED ON CANVAS
        const marker = canvasRef.current.getObjects()[index + 1];
        const dx = point.x - marker.x;
        const dy = point.y - marker.y;
        marker.set({
          initialLeft: point.x,
          initialTop: point.y,
          left: marker.left + dx,
          top: marker.top + dy,
        });
      });
    }
    // canvas doesnt re-render the new points unless updateMarkersScale is run
    updateMarkersScale(canvasRef.current);
    canvasRef.current.requestRenderAll();
    dispatch(setUpdateCoordinates(false));
  }, [areCoordinatesUpdated]);

  useEffect(() => {
    if (!correspondingFloorplanPointId && !correspondingCamerPointId) return;
    if (props.id === "Canvas") {
      if (correspondingFloorplanPointId?.type === "point") {
        // delete from markers ref
        const idxToDelete = markersRef.current.findIndex(
          (marker) => marker.id === correspondingFloorplanPointId.id
        );
        const deletionCount = idxToDelete > 0 ? 1 : 0;
        markersRef.current.splice(idxToDelete, deletionCount);
        // delete from canvas
        const markerToDelete = canvasRef.current
          .getObjects()
          .find(
            (obj) =>
              obj.id === correspondingFloorplanPointId.id &&
              obj.markerType === "point"
          );
        canvasRef.current.remove(markerToDelete);
      } else if (correspondingFloorplanPointId?.type === "test") {
        const idxToDelete = testPointsRef.current.findIndex(
          (test) => test.id === correspondingFloorplanPointId.id
        );
        const deletionCount = idxToDelete > 0 ? 1 : 0;
        testPointsRef.current.splice(idxToDelete, deletionCount);
        // delete from canvas
        const markerToDelete = canvasRef.current
          .getObjects()
          .find(
            (obj) =>
              obj.id === correspondingFloorplanPointId.id &&
              obj.markerType === "test"
          );
        canvasRef.current.remove(markerToDelete);
      }
      canvasRef.current.requestRenderAll();
    } else {
      if (correspondingCamerPointId?.type === "point") {
        const idxToDelete = markersRef.current.findIndex(
          (marker) => marker.id === correspondingCamerPointId.id
        );
        const deletionCount = idxToDelete > 0 ? 1 : 0;
        markersRef.current.splice(idxToDelete, deletionCount);
        // delete from canvas
        const markerToDelete = canvasRef.current
          .getObjects()
          .find(
            (obj) =>
              obj.id === correspondingCamerPointId.id &&
              obj.markerType === "point"
          );
        canvasRef.current.remove(markerToDelete);
      } else if (correspondingCamerPointId?.type === "test") {
        const idxToDelete = testPointsRef.current.findIndex(
          (test) => test.id === correspondingCamerPointId.id
        );
        const deletionCount = idxToDelete > 0 ? 1 : 0;
        testPointsRef.current.splice(idxToDelete, deletionCount);
        // delete from canvas
        const markerToDelete = canvasRef.current
          .getObjects()
          .find(
            (obj) =>
              obj.id === correspondingCamerPointId.id &&
              obj.markerType === "test"
          );
        canvasRef.current.remove(markerToDelete);
      }
    }
    canvasRef.current.requestRenderAll();
  }, [correspondingCamerPointId, correspondingFloorplanPointId]);

  const enclose = (canvas, object) => {
    const { br: brRaw, tl: tlRaw } = object.aCoords;
    const T = canvas.viewportTransform;
    // TO GET THE POSITION OF IMAGE CORNERS AFTER ZOOM OR PAN
    const br = fabric.util.transformPoint(brRaw, T);
    const tl = fabric.util.transformPoint(tlRaw, T);
    const { x: left, y: top } = tl; // DESTRUCTURING WITH ALIAS
    const { x: right, y: bottom } = br;
    const { width, height } = canvas;
    // calculate how far to translate to line up the edge of the object with
    // the edge of the canvas
    const dLeft = Math.abs(right - width);
    const dRight = Math.abs(left);
    const dUp = Math.abs(bottom - height);
    const dDown = Math.abs(top);
    // if the object is larger than the canvas, clamp translation such that
    // we don't push the opposite boundary past the edge
    const maxDx = Math.min(dLeft, dRight);
    const maxDy = Math.min(dUp, dDown);
    const leftIsOver = left < 0;
    const rightIsOver = right > width;
    const topIsOver = top < 0;
    const bottomIsOver = bottom > height;
    const translateLeft = rightIsOver && !leftIsOver;
    const translateRight = leftIsOver && !rightIsOver;
    const translateUp = bottomIsOver && !topIsOver;
    const translateDown = topIsOver && !bottomIsOver;
    // ALL THIS IS DONE TO ADJUST THE CANVAS VIEW IF IT ESCAPES THE CANVAS BOUNDARY ON PANNING
    const dx = translateLeft ? -maxDx : translateRight ? maxDx : 0;
    const dy = translateUp ? -maxDy : translateDown ? maxDy : 0;
    if (dx || dx) {
      T[4] += dx;
      T[5] += dy;
      canvas.requestRenderAll();
    }
  };

  const updateMarkersScale = (canvas) => {
    const zoom = canvas.getZoom();
    canvas
      .getObjects()
      .filter((ob) => ob.type === "circle")
      .forEach((obj, i) => {
        const circle_radius = i === 0 ? 8 : 4;
        let strokeWidth = zoom > 1 ? 2 / zoom : 2;
        let radius = zoom > 1 ? circle_radius / zoom : circle_radius;
        obj.set({
          strokeWidth: strokeWidth,
          radius: radius,
        });
      });
    canvas.getObjects().forEach((obj, i) => {
      if (obj.type !== "group") return;
      const x = obj.initialLeft;
      const y = obj.initialTop;
      // const scaledWidth =
      //   zoom > 1 ? obj.markerWidth / zoom : obj.markerWidth * zoom;
      // const scaledHeight =
      //   zoom > 1 ? obj.markerHeight / zoom : obj.markerHeight * zoom;
      const scaledWidth = obj.markerWidth / zoom;
    const scaledHeight = obj.markerHeight / zoom;
      if (i > 0) {
        obj.set({
          // scaleX: zoom > 1 ? 1 / zoom : zoom,
          // scaleY: zoom > 1 ? 1 / zoom :  zoom,
          scaleX: 1 / zoom,
          scaleY: 1 / zoom,
          left: x - scaledWidth / 2,
          top: y - scaledHeight,
        });
      }
    });
  };
  const addGridLines = (canvas, grid) => {
    const image = canvasImage.current;
    let zoom = canvas.getZoom();
    const { tl, br } = image.aCoords;
    const { y: top } = tl;
    const { x: right, y: bottom } = br;
    const xFactor =
      props.id === "Canvas" ? uploadedImage.width / right : 1 / right;
    const yFactor =
      props.id === "Canvas" ? uploadedImage.height / bottom : 1 / bottom;
    grid.forEach((line, i) => {
      const startPoint = line;
      let startX = startPoint[0] / xFactor;
      let startY = startPoint[1] / yFactor;
      startY = props.id === "Canvas" ? bottom - top - startY : startY - top;
      let strokeWidth = 2;
      let radius = i === 0 ? 8 : 4;
      if (zoom > 1) {
        strokeWidth = strokeWidth / zoom;
        radius = radius / zoom;
      } else if (zoom === 1) {
        strokeWidth = 2;
        radius = i === 0 ? 8 : 4;
      }
      canvas.add(
        new fabric.Circle({
          left: startX,
          top: startY,
          originX: "center",
          originY: "center",
          radius: radius,
          fill: i === 0 ? "#0000ff" : "#ff0000",
          lockMovementX: true,
          strokeWidth: strokeWidth,
          lockMovementY: true,
          evented: false,
        })
      );
    });
    canvas.requestRenderAll();
  };

  useEffect(() => {
    if (storePoints) {
      const image = canvasImage.current;
      const { br } = image.aCoords;
      if (props.id === "Canvas") {
        dispatch(setFloorplanDimensions({ width: br.x, height: br.y }));
        dispatch(setFloorplanPoints(markersRef.current));
      } else {
        dispatch(setCameraSceneDiemsions({ width: br.x, height: br.y }));
        dispatch(setCameraPoints(markersRef.current));
      }
    }
  }, [storePoints]);

  useEffect(() => {
    let x = document.querySelector(".image-mapping-box").offsetWidth;
    let y = document.querySelector(".image-mapping-box").offsetHeight;
    // let canvasImage;
    const initCanvas = (id) =>
      new fabric.Canvas(id, {
        preserveObjectStacking: true,
        width: x,
        height: y,
        fireMiddleClick: true,
        selection: false,
      });
    const onMouseWheel = (opt) => {
      // UPDATE MARKERS ACCORDING TO THE ZOOM SCALE
      updateMarkersScale(canvas);
      const { e } = opt;
      zoomDelta(canvas, e.deltaY, e.offsetX, e.offsetY);
      enclose(canvas, canvasImage.current);
      e.preventDefault();
      e.stopPropagation();
    };
    canvasRef.current = initCanvas(props.id);
    const canvas = canvasRef.current;
    canvas.selection = false;
    // STATEFUL CANVAS OBJECTS CAN STORE AND RESTORE THEIR STATES
    canvas.stateful = true;
    // ADD EVENT LISTENER FOR ZOOM
    canvas.on("mouse:wheel", onMouseWheel);
    // ADD CANVAS IMAGES
    fabric.Image.fromURL(props.currentCameraUrl, function (img) {
      let imageTextureSize = img.width < img.height ? img.width : img.height;
      if (imageTextureSize > fabric.textureSize) {
        fabric.textureSize = imageTextureSize;
      }
      canvasImage.current = img;
      let imageObj = img.set({
        scaleX: x / img.width,
        scaleY: y / img.height,
        id: props.id,
      });
      imageObj.lockMovementX = true;
      imageObj.objectCaching = false;
      imageObj.imageD
      imageObj.lockMovementY = true;
      imageObj.hasBorders = false;
      imageObj.selectable = false;
      imageObj.scaleToWidth(x, true)
      canvas.add(imageObj);
      canvas.renderAll();
      paintMarkersOnCanvas(canvasImage.current);

      if (props.id == "Canvas" && invertFloorplanColors) {
        imageObj.applyFilters();
        canvas.requestRenderAll();
        imageObj.applyFilters([
          new fabric.Image.filters.Contrast({ contrast: 15 }),
        ]);
      }
    });
  }, []);

  const paintMarkersOnCanvas = (image) => {
    if (cameraPoints.length && floorplanPoints.length && image) {
      const { br } = image.aCoords;
      const { x: right, y: bottom } = br;
      const xFactor = calculateScaleFactor(
        props.id,
        uploadedImage.width,
        right
      );
      const yFactor = calculateScaleFactor(
        props.id,
        uploadedImage.height,
        bottom
      );
      if (props.id === "Canvas") {
        const transformedFloorPlanPoints = floorplanPoints.map((point) => ({
          relativeX: point[0],
          relativeY: point[1],
          x: point[0] / xFactor,
          y: bottom - point[1] / yFactor,
        }));
        setFloorplanPoints(transformedFloorPlanPoints);
        uploadMarkers(transformedFloorPlanPoints);
      } else {
        const transformedCameraPoints = cameraPoints.map((point) => ({
          relativeX:
            cameraPointsAvg.yFactor <= 1
              ? point[0]
              : point[0] * (1 / uploadedImage.width),
          relativeY:
            cameraPointsAvg.yFactor <= 1
              ? point[1]
              : point[1] * (1 / uploadedImage.height),
          x: point[0] / xFactor,
          y: point[1] / yFactor,
        }));
        setCameraPoints(transformedCameraPoints);
        uploadMarkers(transformedCameraPoints);
      }
    }
  };

  useEffect(() => {
    const isATransformedPoint = true;
    if (props.testMarker) addTestMarker(props.testMarker, isATransformedPoint);
  }, [props.testMarker]);

  const getTransformedPoint = async (testPoint, homography) => {
    let projection_type =
      homography && homography[currentCameraIndex]
        ? homography[currentCameraIndex]["projection_type"]
        : "";
    let testPointApiUrl = "";
    if (projection_type === "Homography") {
      testPointApiUrl =
        Constants.REACT_APP_API + "/projection/homography/testpoint/";
    } else if (projection_type === "TPS") {
      testPointApiUrl = Constants.REACT_APP_API + "/projection/tps/testpoint/";
    } else if (projection_type === "CubicModel") {
      testPointApiUrl =
        Constants.REACT_APP_API + "/projection/cubic/testpoint/";
    } else if (projection_type === "CalibrateCameraModel") {
      testPointApiUrl =
        Constants.REACT_APP_API + "/projection/cv2calibrate/testpoint/";
    }
    let data = {
      test_point: testPoint,
      test_point_type: props.id === "Canvas" ? "world" : "camera",
      projection_model: homography[currentCameraIndex]["projection_model"],
    };
    const response = await fetch(testPointApiUrl, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error(response.status);
    const transformedPoint = await response.json();
    if (props.id === "Canvas") dispatch(setCameraTestMarker(transformedPoint));
    else dispatch(setFloorplanTestMarker(transformedPoint));
  };

  const getMarkerText = (size) => {
    if (size < 10) return `0${size}`;
    return `${size}`;
  };

  const uploadMarkers = (points) => {
    const zoomScale = canvasRef.current.getZoom();
    // THE ABOVE TRANSFORMATION MATRIX CAN BE USED TO GET THE BOUNDARIES OF THE ZOOMED IMAGE
    // TO ADJUST THE COORDINATES ACCORDINGLY
    points.forEach((point, i) => {
      fabric.Image.fromURL(markerImg, (img) => {
        const { x, y } = {
          x: point.x,
          y: point.y,
        };
        const text = new fabric.Text(getMarkerText(i));
        text.left = 5;
        text.top = 5;
        text.fontSize = 12;
        text.fill = props.id === "Canvas" ? "#fff" : "#000";
        const scaledWidth =
          zoomScale > 1 ? img.width / zoomScale : img.width * zoomScale;
        const scaledHeight =
          zoomScale > 1 ? img.height / zoomScale : img.height * zoomScale;
        const marker = new fabric.Group([img, text], {
          scaleX: zoomScale > 1 ? 1 / zoomScale : zoomScale,
          scaleY: zoomScale > 1 ? 1 / zoomScale : zoomScale,
          left: x - scaledWidth / 2,
          top: y - scaledHeight,
          initialLeft: x,
          initialTop: y,
          markerWidth: img.width,
          markerHeight: img.height,
          id: i,
          evented: false,
          markerType: "point",
        });
        markersRef.current[i] = {
          x,
          y,
          relativeX: point.relativeX,
          relativeY: point.relativeY,
          id: i,
        };
        marker.hasControls = false;
        marker.hasBorders = false;
        canvasRef.current.add(marker).renderAll();
      });
    });
  };

  const addMarker = (pointer, isATransformedPoint) => {
    const { x, y } = pointer;
    const zoomScale = canvasRef.current.getZoom();
    // TO ADJUST THE COORDINATES ACCORDINGLY
    const markerImage =
      addTestPointToggle && homography ? pointMarkerTest : markerImg;
    const size = {
      marker: markersRef.current.length,
      test: testPointsRef.current.length,
    };
    const markerId =
      size.marker === 0 ? 0 : markersRef.current[size.marker - 1].id + 1;
    const testId =
      size.test === 0 ? 0 : testPointsRef.current[size.test - 1].id + 1;
    fabric.Image.fromURL(markerImage, (img) => {
      // CHANGE TEXT PROPERTIES BASED ON WHETHER A TEST POINT IS BEING ADDED OR ANOTHER
      const text = new fabric.Text(
        getMarkerText(addTestPointToggle ? testId : markerId)
      );
      text.left = addTestPointToggle ? 6 : 5;
      text.top = addTestPointToggle ? 7 : 5;
      text.fontSize = addTestPointToggle ? 10 : 12;
      text.fill = addTestPointToggle
        ? "#FFFF00"
        : props.id === "Canvas"
        ? "#fff"
        : "#000";
      if (addTestPointToggle) text.backgroundColor = "#000";
      const scaledWidth =
        zoomScale > 1 ? img.width / zoomScale : img.width * zoomScale;
      const scaledHeight =
        zoomScale > 1 ? img.height / zoomScale : img.height * zoomScale;

      const marker = new fabric.Group([img, text], {
        scaleX: zoomScale > 1 ? 1 / zoomScale : zoomScale,
        scaleY: zoomScale > 1 ? 1 / zoomScale : zoomScale,
        left: x - scaledWidth / 2,
        top: y - scaledHeight,
        initialLeft: x,
        initialTop: y,
        markerWidth: img.width,
        markerHeight: img.height,
        id: addTestPointToggle ? testId : markerId,
        evented: false,
        markerType: addTestPointToggle ? "test" : "point",
      });
      //CALCULATE ACTUALX AND ACTUALY COORDINATES
      const image = canvasImage.current;
      const { tl, br } = image.aCoords;
      const { x: left, y: top } = tl;
      const { x: right, y: bottom } = br;
      const xFactor = props.id === "Canvas" ? uploadedImage.width / right : 1 / right;
      const yFactor = props.id === "Canvas" ? uploadedImage.height / bottom : 1 / bottom;
      const relativeX = (x - left) * xFactor;
      const relativeY =
        props.id === "Canvas"
          ? (bottom - top - y) * yFactor
          : (y - top) * yFactor;
      if (isATransformedPoint && props.id === "Canvas") {
        // ADJUST THE TRANSFORMED POINT RETURNED TO BE ACCORDING TO ORIGIN AT TOP LEFT.
        marker.set({
          top: bottom - top - y - scaledHeight,
          initialTop: bottom - top - y,
        });
      }
      if (addTestPointToggle)
        testPointsRef.current = [
          ...testPointsRef.current,
          { ...pointer, relativeX, relativeY, id: testId },
        ];
      else
        markersRef.current = [
          ...markersRef.current,
          { ...pointer, relativeX, relativeY, id: markerId },
        ];
      marker.hasControls = false;
      marker.hasBorders = false;
      // GENERATING CORRESPONDING TEST POINT
      if (addTestPointToggle && !isATransformedPoint) {
        const testPoint = [relativeX, relativeY];
        getTransformedPoint(testPoint, homography);
      }
      canvasRef.current.add(marker).renderAll();
    });

    // TODO: Limit mouse positions to image bounds and not outside
    // HINT: Do account for image dimensions to make this work
  };

  const addTestMarker = (testPoint, isATransformedPoint) => {
    const image = canvasImage.current;
    const { br } = image.aCoords;
    const { x: right, y: bottom } = br;
    // const xFactor = uploadedImage.width / right;
    // const yFactor = uploadedImage.height / bottom;
    const xFactor =
      props.id === "Canvas" ? uploadedImage.width / right : 1 / right;
    const yFactor =
      props.id === "Canvas" ? uploadedImage.height / bottom : 1 / bottom;
    // const cameraXFactor = 1 / right;
    // const cameraYFactor = 1 / bottom;
    let pointer = {
      x: testPoint.test_point_mapped[0] / xFactor,
      y: testPoint.test_point_mapped[1] / yFactor,
    };
    addMarker(pointer, isATransformedPoint);
  };

  const addMarkersHandler = (opt) => {
    // ONLY ALLOW ADDING TEST POINTS IF HOMOGRAPHY HAS BEEN GENERATED
    // IF ADD TEST POINT IS CLICKED BUT HOMOGRAPHY IS NOT GENERATED, DO NOT ALLOW TEST POINTS TO BE ADDED
    if (opt.e.button === 1) return;
    if (addTestPointToggle && !homography) return;
    const pointer = canvasRef.current.getPointer(opt.e);
    addMarker(pointer);
  };
  const clearTestPoints = () => {
    testPointsRef.current = [];
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.getObjects().forEach((obj) => {
      if (obj.type === "group") {
        obj.set({
          evented: selectAndMovePointToggle && obj.markerType === "point",
        });
      } else if (obj.type === "image") {
        obj.set({
          evented: !selectAndMovePointToggle,
        });
      }
    });
  }, [selectAndMovePointToggle, adjustViewPositionToggle]);

  // Reset Testpoints

  useEffect(() => {
    // if homography existed before generating one, the testpoints are reset
    if (homography) {
      const canvas = canvasRef.current;
      clearTestPoints();
      dispatch(hideAddTestPoint())
      canvas.getObjects().forEach((obj) => {
        if (obj.markerType === "circle" || obj.markerType === 'test') {
          canvas.remove(obj);
        }
      });
    }
  }, [homography]);

  useEffect(() => {
    if (!deletePoint) return;
    // only run below code to make sure that points are evented when delete point is toggled
    const canvas = canvasRef.current;
    canvas.getObjects().forEach((obj) => {
      if (obj.type === "group") {
        obj.set({
          evented: deletePoint,
        });
      }
    });
  }, [deletePoint]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.on("mouse:down", onMouseDown);
    canvas.on("mouse:move", onMouseMove);
    canvas.on("mouse:up", onMouseUp);

    if (addPointToggle || addTestPointToggle) {
      canvas.on("mouse:up", addMarkersHandler);
    }
    // CLEAR THE EVENT LISTENER WHEN adjustViewPositionToggle STATE IS UPDATED.
    return () => {
      canvas.off("mouse:down", onMouseDown);
      canvas.off("mouse:move", onMouseMove);
      canvas.off("mouse:up", onMouseUp);
      canvas.off("mouse:up", addMarkersHandler);
    };
  }, [
    adjustViewPositionToggle,
    addPointToggle,
    addTestPointToggle,
    selectAndMovePointToggle,
    deletePoint,
  ]);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (plotGridLines && homography) {
      let homographyData = homography[currentCameraIndex];
      let worldGrid = homographyData.grid_points.world_grid;
      let cameraGrid = homographyData.grid_points.camera_grid;
      canvas.getObjects().forEach((obj) => {
        if (obj.type === "circle") {
          canvas.remove(obj);
        }
      });
      if (props.id === "Canvas") addGridLines(canvasRef.current, worldGrid);
      else addGridLines(canvasRef.current, cameraGrid);
    } else if (!plotGridLines && homography) {
      canvas.getObjects().forEach((obj) => {
        if (obj.type === "circle") {
          canvas.remove(obj);
        }
      });
      // canvas.requestRenderAll();
    }
  }, [plotGridLines, homography]);

  useEffect(() => {
    // IS THIS NEEDED?
    localStorage.setItem("addPointToggle", addPointToggle);
    localStorage.setItem("addTestPointToggle", addTestPointToggle);
  }, [addPointToggle, !addTestPointToggle]);

  useEffect(() => {
    localStorage.setItem("addPointToggle", addPointToggle);
    localStorage.setItem("addTestPointToggle", addTestPointToggle);
    if (editCameraPositionToggle) {
      localStorage.setItem(
        "editCameraPositionToggle",
        editCameraPositionToggle
      );
    }
    return () => {
      localStorage.removeItem("addPointToggle");
      localStorage.removeItem("addTestPointToggle");
      localStorage.removeItem("editCameraPositionToggle");
    };
  }, [addPointToggle, addTestPointToggle, editCameraPositionToggle]);

  // PANNING EVENT HANDLERS
  const getClientPosition = (e) => {
    const positionSource = e.touches ? e.touches[0] : e;
    const { clientX, clientY } = positionSource;
    return {
      clientX,
      clientY,
    };
  };

  const onMouseDown = (opt) => {
    const { e } = opt;
    const target = opt.target;
    // If target is not found, then do nothing
    // furthermore, if middle button is clicked while the deletePoint is toggled, do not early return
    if ((!target && e.button !== 1) || (deletePoint && e.button !== 1)) return;
    if (target?.type === "group" && selectAndMovePointToggle) {
      const { x, y } = opt.absolutePointer;
      const id = target.id;
      markerInitPosition.current = {
        x,
        y,
        id: id,
      };
      return;
    }
    // The following part should only execute for when adjusting image position
    if (
      target?.type !== "group" &&
      selectAndMovePointToggle &&
      !adjustViewPositionToggle &&
      e.button !== 1
    )
      return;
    // Handle case when adjust view button is not pressed and the canvas is clicked through the left mouse button and not middle
    if (!adjustViewPositionToggle && e.button !== 1) return;
    isDragging.current = true;
    isDraggedByMiddleButton.current = true;
    const { clientX, clientY } = getClientPosition(e);
    lastXPos.current = clientX;
    lastYPos.current = clientY;
    canvasRef.current.selection = false;
    canvasRef.current.discardActiveObject();
  };

  const onMouseMove = (opt) => {
    const { e } = opt;
    if (!isDragging.current) return;
    if (!isDraggedByMiddleButton.current) return
    if (!opt.target && !isDraggedByMiddleButton.current ) return;
    if (selectAndMovePointToggle && opt.target?.type === "group") return;
    if (
      opt.target?.type !== "group" &&
      selectAndMovePointToggle &&
      !adjustViewPositionToggle &&
      !isDraggedByMiddleButton.current
    )
      return;
    const T = canvasRef.current.viewportTransform;
    const { clientX, clientY } = getClientPosition(e);
    T[4] += clientX - lastXPos.current;
    T[5] += clientY - lastYPos.current;
    canvasRef.current.requestRenderAll();
    lastXPos.current = clientX;
    lastYPos.current = clientY;
    // enclose(canvasRef.current, canvasImage.current);
  };

  const onMouseUp = (opt) => {
    isDraggedByMiddleButton.current = false;
    if (!opt.currentTarget || !opt.target) return;

    if (deletePoint && opt.target.type === "group" && opt.e.button !== 1) {
      const target = opt.target;
      canvasRef.current.remove(target);
      if (props.id === "Canvas") {
        dispatch(
          setCorrespondingCameraPointId({
            type: target.markerType,
            id: target.id,
          })
        );
      } else {
        dispatch(
          setCorrespondingFloorplanPointId({
            type: target.markerType,
            id: target.id,
          })
        );
      }
      if (target.markerType === "point") {
        const idxToDelete = markersRef.current.findIndex(
          (marker) => marker.id === target.id
        );
        markersRef.current.splice(idxToDelete, 1);
      } else {
        const idxToDelete = testPointsRef.current.findIndex(
          (test) => test.id === target.id
        );
        testPointsRef.current.splice(idxToDelete, 1);
      }
      canvasRef.current.requestRenderAll();
      return;
    }
    if (
      selectAndMovePointToggle &&
      opt.currentTarget.type === "group" &&
      opt.e.button !== 1
    ) {
      const { x, y } = opt.absolutePointer;
      const currentMarker = canvasRef.current
        .getObjects()
        .find((obj) => obj.id === markerInitPosition.current.id);
      //Alternate logic
      const zoom = canvasRef.current.getZoom();
      const dx = markerInitPosition.current.x - x;
      const dy = markerInitPosition.current.y - y;
      const initialX = currentMarker.initialLeft - dx;
      const initialY = currentMarker.initialTop - dy;
      const scaledWidth =
        zoom > 1
          ? currentMarker.markerWidth / zoom
          : currentMarker.markerWidth * zoom;
      const scaledHeight =
        zoom > 1
          ? currentMarker.markerHeight / zoom
          : currentMarker.markerHeight * zoom;
      currentMarker.set({
        initialLeft: initialX,
        initialTop: initialY,
      });
      const image = canvasImage.current;
      const { tl, br } = image.aCoords;
      const { x: right, y: bottom } = br;
      const xFactor = calculateScaleFactor(
        props.id,
        uploadedImage.width,
        right
      );
      const yFactor = calculateScaleFactor(
        props.id,
        uploadedImage.height,
        bottom
      );
      const index = markersRef.current.findIndex(
        (marker) => marker.id === currentMarker.id
      );
      const relativeX = markersRef.current[index].relativeX - dx * xFactor;
      const relativeY =
        props.id === "Canvas"
          ? markersRef.current[index].relativeY + dy * yFactor
          : markersRef.current[index].relativeY - dy * yFactor;

      markersRef.current[index] = {
        x: initialX,
        y: initialY,
        relativeX: relativeX,
        relativeY: relativeY,
        id: currentMarker.id,
        left: initialX - scaledWidth / 2,
        top: initialX - scaledHeight,
      };
      currentMarker.setCoords();
      canvasRef.current.requestRenderAll();
      canvasRef.current.discardActiveObject();
      return;
    }
    if (
      opt.target.type !== "group" &&
      selectAndMovePointToggle &&
      !adjustViewPositionToggle &&
      opt.e.button !== 1
    )
      return;
    canvasRef.current.setViewportTransform(canvasRef.current.viewportTransform);
    isDragging.current = false;
    canvasRef.current.selection = false;
  };

  // Get the width and height for different screens
  const [canvasWidth, setcanvasWidth] = useState(637);
  const [canvasHeight, setCanvasHeight] = useState(637);
  useEffect(() => {
    let x = document.querySelector(".image-mapping-box").offsetWidth;
    let y = document.querySelector(".image-mapping-box").offsetHeight;
    setcanvasWidth(x);
    setCanvasHeight(y);
  }, [canvasWidth, canvasHeight]);

  // FUNCTION TO GET THE DESIRED CURSOR IMAGE SOURCE
  const getCursorSrc = useCallback(() => {
    if (selectAndMovePointToggle === true) return cursorMove;
    else if (adjustViewPositionToggle) return adjView;
    else return crossHair;
  }, [selectAndMovePointToggle, adjustViewPositionToggle]);

  return (
    <Fragment>
      <CanvasWithImage id={props.id} getCursorSrc={getCursorSrc} />
    </Fragment>
  );
};

export default CustomReactPictureAnnotation;

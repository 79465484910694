import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import './Alert.css';

function Alert({ show, onHide, errorMessage }) {
  return (
    <Toast onClose={onHide} show={show}>
      <Toast.Header>
        <strong className="me-auto">Save Project</strong>
      </Toast.Header>
      <Toast.Body>
        {errorMessage ?
          <div className="alert-error">
            <p>Downloading Failed.</p>
            <p>Error: {errorMessage}</p>
          </div>
          :
          <React.Fragment>
            <p>
              Download successful
            </p>
          </React.Fragment>
        }
      </Toast.Body>
    </Toast>
  );
}

export default Alert;

Alert.propTypes = {
  show: PropTypes.string,
  onHide: PropTypes.func,
  errorMessage: PropTypes.string,
};
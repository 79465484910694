import {
    SET_IMAGE_URLS,
    SET_CAMERA_IMAGE_URLS,
    SET_CREATE_PROJECT_DATA,
    SET_CURRENT_CAMERA_IMAGE_URL,
    SET_FLOOR_PLAN_MARKERS,
    SET_CAMERA_MARKERS,
    SET_FLOOR_PLAN_TEST_POINT,
    SET_CAMERA_TEST_POINT,
    ADD_FLOOR_PLAN_TEST_POINT,
    ADD_CAMERA_TEST_POINT,
    SET_LOAD_CAMERA_URL,
    ENABLE_ADJUST_VIEW_POSITION,
    ENABLE_CAMERA_ADJUST_VIEW_POSITION,
    SET_CAMERA_POSITION_MARKER,
    SET_CURRENT_CAMERA_IMAGE_INDEX,
    SET_CURRENT_CAMERA_NOTES,
    SET_SELECTED_CAMERA_MARKER,
    SET_SELECTED_FLOOR_MARKER,
    SET_HOMOGRAPHY,
    ZOOM_SCALE,
    CAMERA_ZOOM_SCALE,
    FLOOR_VIEW_POSITION,
    CAMERA_VIEW_POSITION,
    ZOOM_IN_TOGGLE,
    ZOOM_OUT_TOGGLE,
    ZOOM_CANVAS_ID,
    SET_COUNT_HOMOGRAPHY_CALL,
    SET_MASK_SHAPES,
    SET_SELECTED_FLOOR_TEST_POINT,
    SET_SELECTED_CAMERA_TEST_POINT,
    SET_FLOOR_PLAN_POINTS,
    SET_CAMERA_POINTS,
    SET_FLOORPLAN_TEST_MARKER,
    SET_CAMERA_TEST_MARKER,
    SET_FLOOR_PLAN_BOUNDS,
    SET_CAMERA_BOUNDS,
    SET_FLOORPLAN_DIMENSIONS,
    SET_CAMERA_DIMENSIONS,
    SET_IMAGE_FILE,
    SET_CAMERA_FILES,
    SET_IMAGE_UPLOADED,
    SET_CAMERA_IMAGE_UPLOADED,
    SET_CORRESPONDING_POINT_ID,
    SET_CORRESPONDING_CAMERA_POINT_ID,
    SET_CORRESPONDING_FLOORPLAN_POINT_ID,
    SET_CAMERA_POINTS_AVG
} from './constants';

export const setImageUrls = (urls) => ({
    type: SET_IMAGE_URLS, payload: urls
});

export const setImageFiles = (files) => ({
    type: SET_IMAGE_FILE, payload: files
})

export const setImageUploaded = (file) => ({
    type: SET_IMAGE_UPLOADED, payload: file
})

export const setCameraImageUploaded = (file) => ({
    type: SET_CAMERA_IMAGE_UPLOADED, payload: file
})

export const setCameraFiles = (files) => ({
    type: SET_CAMERA_FILES, payload: files
})

export const setCameraImageUrls = (urls) => ({
    type: SET_CAMERA_IMAGE_URLS, payload: urls
});

export const setcurrentCameraUrl = (urls) => ({
    type: SET_CURRENT_CAMERA_IMAGE_URL, payload: urls
});

export const setcurrentcameraNotes = (notes) => ({
    type: SET_CURRENT_CAMERA_NOTES, payload: notes
});

export const setcurrentCameraImageIndex = (index) => ({
    type: SET_CURRENT_CAMERA_IMAGE_INDEX, payload: index
});

export const setCreateProjectData = (data) => ({
    type: SET_CREATE_PROJECT_DATA, payload: data
});

export const setFloorPlanTestPoint = (data) => ({
    type: SET_FLOOR_PLAN_TEST_POINT, payload: data
});

export const setCameraTestPoint = (data) => ({
    type: SET_CAMERA_TEST_POINT, payload: data
});

export const addFloorPlanTestPoint = (data) => ({
    type: ADD_FLOOR_PLAN_TEST_POINT, payload: data
});

export const addCameraTestPoint = (data) => ({
    type: ADD_CAMERA_TEST_POINT, payload: data
});

export const setFloorplanmarkers = (data) => ({
    type: SET_FLOOR_PLAN_MARKERS, payload: data
});

export const setCameramarkers = (data) => ({
    type: SET_CAMERA_MARKERS, payload: data
});

export const setloadCameraUrl = (data) => ({
    type: SET_LOAD_CAMERA_URL, payload: data
});

export const setSelectedFloorMarker = (data) => ({
    type: SET_SELECTED_FLOOR_MARKER, payload: data
});

export const setSelectedCameraMarker = (data) => ({
    type: SET_SELECTED_CAMERA_MARKER, payload: data
});

export const setSelectedCameraTestPoint = (data) => ({
    type: SET_SELECTED_CAMERA_TEST_POINT, payload: data
});

export const setSelectedFloorTestPoint = (data) => ({
    type: SET_SELECTED_FLOOR_TEST_POINT, payload: data
});

export const setMaskShapes = (data) => ({
    type: SET_MASK_SHAPES, payload: data
});

export const setAdjustViewPosition = (data) => ({
    type: ENABLE_ADJUST_VIEW_POSITION, payload: data
});
export const setCameraAdjustViewPosition = (data) => ({
    type: ENABLE_CAMERA_ADJUST_VIEW_POSITION, payload: data
});

export const setZoomScale = (data) => ({
    type: ZOOM_SCALE, payload: data
});

export const setCameraZoomScale = (data) => ({
    type: CAMERA_ZOOM_SCALE, payload: data
});

export const setFloorViewPosition = (data) => ({
    type: FLOOR_VIEW_POSITION, payload: data
});

export const setCameraViewPosition = (data) => ({
    type: CAMERA_VIEW_POSITION, payload: data
});

export const setZoomInToggle = (data) => ({
    type: ZOOM_IN_TOGGLE, payload: data
});

export const setZoomOutToggle = (data) => ({
    type: ZOOM_OUT_TOGGLE, payload: data
});

export const setZoomCanvasId = (data) => ({
    type: ZOOM_CANVAS_ID, payload: data
});

export const setCameraPositionMarker = (data) => ({
    type: SET_CAMERA_POSITION_MARKER, payload: data
});

export const setHomography = (data) => ({
    type: SET_HOMOGRAPHY, payload: data
});

export const setCountHomographyCall = data => ({
    type: SET_COUNT_HOMOGRAPHY_CALL, payload: data
});

export const setFloorplanPoints = data => ({
    type: SET_FLOOR_PLAN_POINTS, payload: data
})

export const setCameraPoints = data => ({
    type: SET_CAMERA_POINTS, payload: data
})

// NEW ACTIONS FOR FLOORPLAN AND CAMERA TEST POINTS

export const setFloorplanTestMarker = data => ({
    type: SET_FLOORPLAN_TEST_MARKER, payload: data
})

export const setCameraTestMarker = data => ({
    type: SET_CAMERA_TEST_MARKER, payload: data
})

export const setFloorplanBounds = data => ({
    type: SET_FLOOR_PLAN_BOUNDS, payload: data
})

export const setCameraBounds = data => ({
    type: SET_CAMERA_BOUNDS, payload: data
})

export const setFloorplanDimensions = data => ({
    type: SET_FLOORPLAN_DIMENSIONS, payload: data
})

export const setCameraSceneDiemsions = data => ({
    type: SET_CAMERA_DIMENSIONS, payload: data
})

export const setCorrespondingCameraPointId = data => ({
    type: SET_CORRESPONDING_CAMERA_POINT_ID, payload: data
})

export const setCorrespondingFloorplanPointId = data => ({
    type: SET_CORRESPONDING_FLOORPLAN_POINT_ID, payload: data
})

export const setCameraPointsAvg = data => ({
    type: SET_CAMERA_POINTS_AVG, payload: data
})
import React, { memo } from "react";

const CanvasWithImage = (props) => {

  return (
    <>
      <img
        className="cursor"
        id={props.id === "Canvas" ? "floorCursor" : "cameraCursor"}
        src={props.getCursorSrc()}
      />
      <canvas id={props.id} />
    </>
  );
};

const CanvasWithImageMemoized = memo(CanvasWithImage);
export { CanvasWithImageMemoized as CanvasWithImage };

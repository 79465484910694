import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from './../../assets/images/pathr.ai_logo_stacked_on-blk.png';
import Calibration_logo from './../../assets/images/calibration-logo.png';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import HomeLinkPopup from '../Popups/HomeLinkPopup/HomeLinkPopup';
import './Header.css';
import { setFloorViewPosition, setCameraViewPosition, setCameraZoomScale, setZoomScale, setZoomCanvasId, setZoomInToggle, setZoomOutToggle, setcurrentCameraUrl, setloadCameraUrl, setcurrentCameraImageIndex } from './../../actions/imageData';
import SaveWithoutHomography from './../Popups/SaveWithoutHomography/SaveWithoutHomography';
import {
  showAddScenePopup,
  togglePlotGridLines,
  toggleInvertFloorplanColors,
  toggleChangePointColors,
  showSaveProject,
} from './../../actions/imageOptions';

function Header() {
  const dispatch = useDispatch();
  const project = useSelector(state => state.imageDataReducer.project);
  const show = useSelector(state => state.imageBtnOptions);
  const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
  // const loadCameraUrl = useSelector(state => state.imageDataReducer.loadCameraUrl);
  const plotGridLines = useSelector(state => state.imageOptionsReducer.plotGridLines);
  const invertFloorplanColors = useSelector(state => state.imageOptionsReducer.invertFloorplanColors);
  const changePointColors = useSelector(state => state.imageOptionsReducer.changePointColors);
  const [saveWithoutHomographyPopup, setsaveWithoutHomographyPopup] = useState(false);
  const homographyData = useSelector(state => state.imageDataReducer.homography);


  let i = 0;
  const loadCameraUrlfn = (cameraImage, imageIndex) => {
    dispatch(setcurrentCameraUrl(cameraImage));
    dispatch(setcurrentCameraImageIndex(imageIndex));
    dispatch(setloadCameraUrl(true));
  }
  const [homeLinkPopop, setHomeLinkPopop] = useState(false);
  const [cameraImages, setcameraImages] = useState([]);
  const handleClose = () => setHomeLinkPopop(false);
  const logoHandler = () => {
    setHomeLinkPopop(true);
  }

  const loadDropdownFn = () => {
    setcameraImages([...cameraImageUrls]);
    if (plotGridLines) {
      dispatch(togglePlotGridLines());
    }
    if (invertFloorplanColors) {
      dispatch(toggleInvertFloorplanColors());
    }
    if (changePointColors) {
      dispatch(toggleChangePointColors());
    }
    dispatch(setZoomCanvasId(""));
    dispatch(setZoomScale(1));
    dispatch(setCameraZoomScale(1));
    dispatch(setZoomInToggle(false));
    dispatch(setZoomOutToggle(false));
    dispatch(setFloorViewPosition(""));
    dispatch(setCameraViewPosition(""));
  }

  const handleSaveProject = () => {
    if (homographyData && homographyData.length !== 0 && homographyData !== undefined) {
      dispatch(showSaveProject());
    } else {
      setsaveWithoutHomographyPopup(true);
    }
  }
  return (
    <div className="Header">
      {saveWithoutHomographyPopup && <SaveWithoutHomography show={saveWithoutHomographyPopup} onHide={() => setsaveWithoutHomographyPopup(false)} />}
      <HomeLinkPopup show={homeLinkPopop} onHide={handleClose} />
      <div className="flex-container">
        <div className="logo-container" >
          <img src={Logo} alt="pathr-logo" className="logo" />
          < img src={Calibration_logo} alt="calibration-logo" className={`calibration-logo ${show ? "pointer-logo" : ""}`} onClick={show ? logoHandler : null} />
          <div className="logo-heading">
            <h1>Calibration Tool</h1>
            <h4>Version 0.3A</h4>
          </div>
        </div>
        {show &&
          <button id="saveProject" className="viewbutton" onClick={handleSaveProject}>Save Project</button>
        }
        {(show) &&
          <DropdownButton id="button-scene-library" title="SCENE LIBRARY" onClick={loadDropdownFn}>
            <Dropdown.Item onClick={() => dispatch(showAddScenePopup())} className="addScene-item">ADD SCENE</Dropdown.Item>
            {cameraImages && cameraImages.map((item) => {
              let itemSplit = item.split(";");
              let fileName = itemSplit[1].split("name=");
              let imageIndex = i++;
              return (
                <Dropdown.Item value={item} onClick={() => loadCameraUrlfn(item, imageIndex)} >{fileName[1]}</Dropdown.Item>
              );
            })}
          </DropdownButton>
        }
        <div>
          {show && <div className="project-info">
            <div className="client-name" title={project.client_name} >{project.client_name.length > 15 ? project.client_name.slice(0, 14) + "..." : project.client_name}</div>
            <div className="project-name" title={project.project_name} >{project.project_name.length > 15 ? project.project_name.slice(0, 14) + "..." : project.project_name}</div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
// import CustomReactPictureAnnotation from "../ImageAnnotationComponent";
import ViewPointCoordinates from "./../ViewPointCoordinates/ViewPointCoordinates";
import EditScene from "./../EditScene.js/EditScene";
import ViewOptions from "../ViewOptions/ViewOptions";
import { useSelector, useDispatch } from "react-redux";
import {
  hideViewPointCoordinates,
  showEditScene,
  hideEditScene,
  hideSaveProject,
  hideViewOptions,
  hideInfoPopup,
  // showInfoPopup,
  hideAddScenePopup,
  // hideAddPoint,
  // hideAddTestPoint,
  // hideMovePoint
} from "./../../actions/imageOptions";
import Alert from "./../helpers/alert/Alert";
import SaveProjectToHomePopup from "./../Popups/SaveProjectToHomePopup/SaveProjectToHomePopup";
import InfoPopup from "./../Popups/InfoPopup/InfoPopup";
import "./ImageMapping.css";
import CustomMessagePopup from "./../Popups/CustomMessagePopup/CustomMessagePopup";
import AddScenePopup from "../AddScenePopup/AddScenePopup";
// import { useHistory } from "react-router-dom";
import * as Constants from "./../../constants";
import {
  setSelectedFloorTestPoint,
  setSelectedCameraTestPoint,
  setCameraZoomScale,
  setZoomScale,
  setZoomCanvasId,
  setZoomInToggle,
  setZoomOutToggle,
  setAdjustViewPosition,
  setCameraAdjustViewPosition,
  setloadCameraUrl,
  setCameramarkers,
  setFloorplanmarkers,
  setSelectedFloorMarker,
  setSelectedCameraMarker,
  addFloorPlanTestPoint,
  setFloorPlanTestPoint,
  addCameraTestPoint,
  setCameraTestPoint,
} from "./../../actions/imageData";
// import crossHair from './../../assets/images/cursor-crosshairs.svg';
import { Fragment } from "react";
import { saveAs } from "file-saver";
import FloorPlanView from "./components/FloorPlanView";
import CamerSceneView from "./components/CameraSceneView";
import JsZip from "jszip";

function ImageMapping() {
  // const history = useHistory();
  const [saveProjectToHomePopup, setSaveProjectToHomePopup] = useState(false);
  const [saveProjectError, setSaveProjectError] = useState("");
  const [customMessagePopup, setCustomMessagePopup] = useState(false);
  const addScenePopup = useSelector(
    (state) => state.imageOptionsReducer.addScenePopup
  );
  const viewPointCoordinatesToggle = useSelector(
    (state) => state.imageOptionsReducer.viewPointCoordinatesToggle
  );
  const editSceneToggle = useSelector(
    (state) => state.imageOptionsReducer.editSceneToggle
  );
  const viewOptionsToggle = useSelector(
    (state) => state.imageOptionsReducer.viewOptionsToggle
  );
  const saveProjectToggle = useSelector(
    (state) => state.imageOptionsReducer.saveProjectToggle
  );
  const addPointToggle = useSelector(
    (state) => state.imageOptionsReducer.addPointToggle
  );
  const infoPopupToggle = useSelector(
    (state) => state.imageOptionsReducer.infoPopup
  );
  const selectAndMovePointToggle = useSelector(
    (state) => state.imageOptionsReducer.selectAndMovePointToggle
  );
  // const adjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.adjustViewPositionToggle);
  // const cameraAdjustViewPositionToggle = useSelector(state => state.imageOptionsReducer.cameraAdjustViewPositionToggle);
  const currentCameraNotes = useSelector(
    (state) => state.imageDataReducer.currentCameraNotes
  );
  const selectedCameraPoint = useSelector(
    (state) => state.imageDataReducer.selectedCameraMarker
  );
  const selectedFloorPoint = useSelector(
    (state) => state.imageDataReducer.selectedFloorMarker
  );
  // const loadCameraUrl = useSelector(state => state.imageDataReducer.loadCameraUrl);
  // const generate_tps = useSelector(state => state.imageOptionsReducer.generateTPS);
  // const generate = useSelector(state => state.imageOptionsReducer.generateHomography);
  let floorPlanAPITestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanAPITestPoints
  );
  let cameraAPITestPoints = useSelector(
    (state) => state.imageDataReducer.cameraAPITestPoints
  );
  let floorPlanTestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanTestPoints
  );
  let cameraTestPoints = useSelector(
    (state) => state.imageDataReducer.cameraTestPoints
  );
  const maskShapes = useSelector((state) => state.imageDataReducer.maskShapes);
  const homography = useSelector((state) => state.imageDataReducer.homography);
  const floorplanPoints = useSelector(
    (state) => state.imageDataReducer.floorplanPoints
  );
  const cameraPoints = useSelector(
    (state) => state.imageDataReducer.cameraPoints
  );
  // const zoomScale = useSelector(state => state.imageOptionsReducer.zoomScale);
  // const cameraZoomScale = useSelector(state => state.imageOptionsReducer.cameraZoomScale);
  // const imageMappingLoading = useSelector(state => state.imageOptionsReducer.imageMappingLoading);

  const selectedCameraTestPoint = useSelector(
    (state) => state.imageDataReducer.selectedCameraTestPoint
  );
  const selectedFloorTestPoint = useSelector(
    (state) => state.imageDataReducer.selectedFloorTestPoint
  );
  // const plotGridLines = useSelector(state => state.imageOptionsReducer.plotGridLines);
  let selectedPoint = "";
  let selectedPointValue = "";
  let testPointFlag = false;
  if (
    selectedCameraPoint !== undefined &&
    selectedCameraPoint !== null &&
    selectedCameraPoint !== ""
  ) {
    testPointFlag = false;
    selectedPoint =
      selectedCameraPoint < 10
        ? "0" + selectedCameraPoint.toString()
        : selectedCameraPoint.toString();
    selectedPointValue = selectedCameraPoint;
  } else if (
    selectedFloorPoint !== undefined &&
    selectedFloorPoint !== null &&
    selectedFloorPoint !== ""
  ) {
    testPointFlag = false;
    selectedPoint =
      selectedFloorPoint < 10
        ? "0" + selectedFloorPoint.toString()
        : selectedFloorPoint.toString();
    selectedPointValue = selectedFloorPoint;
  } else if (
    selectedCameraTestPoint !== undefined &&
    selectedCameraTestPoint !== null &&
    selectedCameraTestPoint !== ""
  ) {
    testPointFlag = true;
    selectedPoint =
      selectedCameraTestPoint < 10
        ? "Test Point 0" + selectedCameraTestPoint.toString()
        : "Test Point " + selectedCameraTestPoint.toString();
    selectedPointValue = selectedCameraTestPoint;
  } else if (
    selectedFloorTestPoint !== undefined &&
    selectedFloorTestPoint !== null &&
    selectedFloorTestPoint !== ""
  ) {
    testPointFlag = true;
    selectedPoint =
      selectedFloorTestPoint < 10
        ? "Test Point 0" + selectedFloorTestPoint.toString()
        : "Test Point " + selectedFloorTestPoint.toString();
    selectedPointValue = selectedFloorTestPoint;
  }

  const dispatch = useDispatch();
  const imageUrls = useSelector((state) => state.imageDataReducer.imageUrls);
  const cameraImageUrls = useSelector(
    (state) => state.imageDataReducer.cameraImageUrls
  );
  const getCurrentCameraUrl = useSelector(
    (state) => state.imageDataReducer.currentCameraUrl
  );
  let itemSplit = getCurrentCameraUrl
    ? getCurrentCameraUrl.split(";")
    : cameraImageUrls[0].split(";");
  const cameraName = itemSplit[1].split("name=");
  const projectDetails = useSelector((state) => state.imageDataReducer.project);
  const floorplanmarkers = useSelector(
    (state) => state.imageDataReducer.floorplanmarkers
  );
  const cameramarkers = useSelector(
    (state) => state.imageDataReducer.cameramarkers
  );
  let currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const imageFiles = useSelector((state) => state.imageDataReducer.imageFiles);
  const cameraFiles = useSelector(
    (state) => state.imageDataReducer.cameraFiles
  );
  const imageUploaded = useSelector(state => state.imageDataReducer.imageUploaded)
  const cameraImageUploaded = useSelector(state => state.imageDataReducer.cameraImageUploaded)
  // const zoomCanvasId = useSelector(state => state.imageOptionsReducer.zoomCanvasId);

  const [fakeState, setFakeState] = useState(false);
  if (currentCameraIndex === undefined) currentCameraIndex = 0;

  useEffect(() => {
    saveProject();
  }, [saveProjectToggle]);

  const customeClickEditScene = () => {
    dispatch(hideEditScene());
    dispatch(setAdjustViewPosition(false));
  };

  const saveProject = () => {
    dispatch(setAdjustViewPosition(false));


    if (saveProjectToggle) {
      var cameraData = [];

      const zip = new JsZip();
      // handle saving floorplan image

      const floorplanFilename = imageUploaded?.name? imageUploaded.name: imageFiles[0].name;
      const cameraSceneFilename = cameraImageUploaded?.name? cameraImageUploaded.name: cameraFiles[0].name;

      if (imageUploaded.data) {
        zip.folder("floorplan").file(imageUploaded?.name, imageUploaded?.data.split('base64,')[1], {base64: true});
      } else {
        zip.folder("floorplan").file(imageFiles[0].name, imageFiles[0]);
      }
      // handle saving camera image
      if (cameraImageUploaded.data) {
        zip.folder("camera").file(cameraImageUploaded?.name, cameraImageUploaded?.data.split('base64,')[1], {base64: true});
      } else {
        zip.folder("camera").file(cameraFiles[0].name, cameraFiles[0]);
      }
      const zipFileName = `${projectDetails.client_name}_${projectDetails.building_name}_${projectDetails.project_name}`;

      for (const [i, scene] of cameraImageUrls.entries()) {
        var fl_markers = [];
        var camera_markers = [];

        if (floorplanPoints) {
          if (floorplanPoints && floorplanPoints !== undefined) {
            fl_markers = floorplanPoints.map(function (val, index) {

              if (val !== undefined)
                return [val["relativeX"], val["relativeY"], 1];
            });
          }
        }
        if (cameraPoints) {
          if (cameraPoints && cameraPoints !== undefined) {
            camera_markers = cameraPoints.map(function (val, index) {
              if (val !== undefined)
                return [val["relativeX"], val["relativeY"]];
            });
          }
        }

        let notes =
          currentCameraNotes && currentCameraNotes[i] !== undefined
            ? currentCameraNotes[i]
            : "";
        let masks =
          maskShapes && maskShapes[i] !== undefined ? maskShapes[i] : "";
        cameraData.push({
          notes: notes,
          camera_points: camera_markers,
          world_points: fl_markers,
          filename: `./${zipFileName}/camera/${cameraSceneFilename}`,
          projection_type:
            homography && homography[i] ? homography[i]["projection_type"] : "",
          projection_model:
            homography && homography[i]
              ? homography[i]["projection_model"]
              : "",
          projection_error:
            homography && homography[i] ? homography[i]["rmse"] : "",
          camera_mask: masks,
          camera_location: [],
        });
      }

      // var data = {
      //     client_name: projectDetails.client_name,
      //     site_name: projectDetails.building_name,
      //     project_name: projectDetails.project_name,
      //     floorplan_filename: imageUrls,
      //     camera_A_filename: cameraImageUrls[0],
      //     cameraData
      // };
      var data = {
        client_name: projectDetails.client_name,
        site_name: projectDetails.building_name,
        project_name: projectDetails.project_name,
        floorplan_filename: `./${zipFileName}/floorplan/${floorplanFilename}`,
        cameraData,
      };
      const dataJson = JSON.stringify(data);
      const blob = new Blob([dataJson], { type: "application/json" });
      zip.file("data.json", blob);
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${zipFileName}.zip`);
      });
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      headers.append("Origin", Constants.REACT_APP_APIORIGIN);
      // fetch(Constants.REACT_APP_APIURL + "/createFolder",
      //     {
      //         mode: 'cors',
      //         method: "POST",
      //         body: JSON.stringify(data),
      //         headers: headers
      //     })
      //     .then(function (res) {
      //         return res.json();
      //     })
      //     .then(function (data) {
      //         if (data.success == "true") {
      //             var zip_file_path = Constants.REACT_APP_APIURL + "/zipFileData?filename=" + data.projectZipFile + ".zip" //put inside "" your path with file.zip
      //             var downloadWindow = window.open(zip_file_path);
      //             dispatch(hideSaveProject());
      //             setSaveProjectToHomePopup(true);
      //             if(downloadWindow && downloadWindow !== undefined){
      //               downloadWindow.onbeforeunload = function () {
      //                 fetch(Constants.REACT_APP_APIURL + "/removeSavedZipFile",
      //                 {
      //                     mode: 'cors',
      //                     method: "POST",
      //                     body: JSON.stringify({"zip_filename" : data.projectZipFile + ".zip"}),
      //                     headers: headers
      //                 });
      //               }
      //             }
      //             else{
      //               setTimeout(function(){
      //                 var downloadWindow = window.open(zip_file_path);
      //                 downloadWindow.onbeforeunload = function () {
      //                   fetch(Constants.REACT_APP_APIURL + "/removeSavedZipFile",
      //                   {
      //                       mode: 'cors',
      //                       method: "POST",
      //                       body: JSON.stringify({"zip_filename" : data.projectZipFile + ".zip"}),
      //                       headers: headers
      //                   });
      //                 }
      //               }, 1000);
      //             }
      //         }
      //     })
      //     .catch((error) => {
      //         setSaveProjectError(error.message);
      //     });
    }
  };

  const handleDeletePoint = (e) => {
    let point = e.currentTarget.dataset.point;
    if (
      selectedCameraPoint !== undefined &&
      selectedCameraPoint !== null &&
      selectedCameraPoint !== ""
    ) {
      cameramarkers[currentCameraIndex].splice(point, 1);
      setCameramarkers(cameramarkers);
      setFakeState(!fakeState);
    } else if (
      selectedFloorPoint !== undefined &&
      selectedFloorPoint !== null &&
      selectedFloorPoint !== ""
    ) {
      floorplanmarkers[currentCameraIndex].splice(point, 1);
      setFloorplanmarkers(floorplanmarkers);
    } else if (
      selectedCameraTestPoint !== undefined &&
      selectedCameraTestPoint !== null &&
      selectedCameraTestPoint !== ""
    ) {
      cameraTestPoints[currentCameraIndex].splice(point, 1);
      floorPlanAPITestPoints[currentCameraIndex].splice(point, 1);
    } else if (
      selectedFloorTestPoint !== undefined &&
      selectedFloorTestPoint !== null &&
      selectedFloorTestPoint !== ""
    ) {
      floorPlanTestPoints[currentCameraIndex].splice(point, 1);
      cameraAPITestPoints[currentCameraIndex].splice(point, 1);
    }
    dispatch(setloadCameraUrl(true));
    dispatch(setSelectedFloorTestPoint(""));
    dispatch(setSelectedCameraTestPoint(""));
    dispatch(setSelectedFloorMarker(""));
    dispatch(setSelectedCameraMarker(""));
    dispatch(setAdjustViewPosition(false));
  };

  return (
    <Fragment>
      <div className="ImageMapping">
        <InfoPopup
          show={infoPopupToggle}
          onHide={() => dispatch(hideInfoPopup())}
        />
        <CustomMessagePopup
          show={customMessagePopup}
          onHide={() => setCustomMessagePopup(false)}
          label="Zoom works with mouse scroll. Zoom Button will work in Version II"
        />
        <Alert
          show={saveProjectToggle}
          onHide={() => dispatch(hideSaveProject())}
        />
        <SaveProjectToHomePopup
          show={saveProjectToHomePopup}
          onHide={() => {
            setSaveProjectToHomePopup(false);
          }}
        />
        <AddScenePopup
          show={addScenePopup}
          onHide={() => {
            dispatch(hideAddScenePopup());
          }}
        />
        <ViewPointCoordinates
          show={viewPointCoordinatesToggle}
          onHide={() => dispatch(hideViewPointCoordinates())}
        />
        {editSceneToggle && (
          <EditScene
            show={editSceneToggle}
            onHide={() => customeClickEditScene()}
          />
        )}
        <ViewOptions
          show={viewOptionsToggle}
          onHide={() => dispatch(hideViewOptions())}
        />
        <div className="container-sceneTitle">
          <span className="sceneTitle">Scene:</span>
          <div className="sceneName">{cameraName}</div>
          <div
            className="editScene"
            onClick={() => dispatch(showEditScene())}
          ></div>
        </div>
        {currentCameraNotes && (
          <div className="scenNotes">
            {currentCameraNotes[currentCameraIndex]}
          </div>
        )}
        <p className="scene-description"></p>
        <div className="image-outer-box">
          {/* <div className="image-inner-box">
                    <div className="image-inner-box-header">
                      <h3>FLOOR PLAN</h3>                      
                      <div className="viewbuttongroup">
                        <button className="viewBTN zoomIn" onClick={setZoomIn} data-id = {"canvas"} >Increase Zoom</button>
                        <button className="viewBTN zoomOut" onClick={setZoomOut} data-id = {"canvas"} >Decrease Zoom</button>
                        <button className="viewBTN position" onClick={adjustViewPosition} >Adjust View Position</button>
                      </div>                       
                    </div>
										{(currentCameraNotes || !currentCameraNotes )&&
											<div className="image-mapping-box floorPlanSection" onMouseMove={handleOnMouseMoveFloor} onMouseLeave={handleOnMouseLeave} onMouseEnter={handleOnMouseEnterFloor}>
													<img className="cursor" id = "floorCursor" src={crossHair}/>
													<CustomReactPictureAnnotation id="Canvas" floorPlan={true} currentCameraUrl="" adjustViewPositionToggle = {adjustViewPositionToggle} setSelectedMarkers = {setSelectedMarkers} addTestPointAPI = {addTestPointAPI} updateTestPointAPI = {updateTestPointAPI} setSelectedTestMarkers = {setSelectedTestMarkers} />
											</div>
										}
                </div> */}
          <FloorPlanView />
          {/* <div className="image-inner-box">
                    <div className="image-inner-box-header">
                      <h3>SCENE</h3>
                      <div className="viewbuttongroup">
                        <button className="viewBTN zoomIn" onClick={setZoomIn} data-id = {"cameraCanvas"} >Increase Zoom</button>
                        <button className="viewBTN zoomOut" onClick={setZoomOut} data-id = {"cameraCanvas"} >Decrease Zoom</button>
                        <button className="viewBTN position" onClick={cameraAdjustViewPosition} >Adjust View Position</button>
                      </div>
                    </div>
										{(currentCameraNotes || !currentCameraNotes ) &&
											<div className="image-mapping-box cameraCanvasSection" onMouseMove={handleOnMouseMove} onMouseLeave={handleOnMouseLeave} onMouseEnter = {handleOnMouseEnter}>
													<img className="cursor" id = "cameraCursor" src={crossHair} />
													<CustomReactPictureAnnotation id="CameraCanvas" currentCameraUrl={getCurrentCameraUrl ? getCurrentCameraUrl : cameraImageUrls[0]} floorPlan={false} cameraAdjustViewPositionToggle = {cameraAdjustViewPositionToggle} setSelectedMarkers = {setSelectedMarkers} addTestPointAPI = {addTestPointAPI} updateTestPointAPI = {updateTestPointAPI} setSelectedTestMarkers = {setSelectedTestMarkers} />
											</div>
										}
                </div> */}
          <CamerSceneView />
        </div>
        {selectAndMovePointToggle && (
          <div className="deletePointBox">
            <button
              className="BTN-green-delete"
              onClick={handleDeletePoint}
              data-point={selectedPointValue}
            >
              Delete {!testPointFlag ? "Point" : ""} {selectedPoint}
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default ImageMapping;

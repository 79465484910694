import { 
    SHOW_ADD_POINT,
    SHOW_ADD_TEST_POINT,
    HIDE_ADD_TEST_POINT,
    HIDE_ADD_POINT,
    SHOW_EDIT_CAMERA_POINT,
    HIDE_EDIT_CAMERA_POINT,
    SHOW_MOVE_POINT,
    HIDE_MOVE_POINT,
    SHOW_VIEW_POINT_COORDINATES,
    HIDE_VIEW_POINT_COORDINATES,
    SHOW_SAVE_PROJECT,
    HIDE_SAVE_PROJECT,
    SHOW_VIEW_OPTIONS,
    HIDE_VIEW_OPTIONS,
    SHOW_EDIT_SCENE,
    HIDE_EDIT_SCENE,
    SHOW_SCENE_LIBRARY,
    HIDE_SCENE_LIBRARY,
    TOGGLE_All_CAMERAS,
    TOGGLE_INVERT_FLOORPLAN_COLORS,
    TOGGLE_POINT_COLORS,
    TOGGLE_PLOT_GRIDLINES,
    IMAGE_MAPPING_LOADING,
    SCENE_MULTIPLE_FLAG,
    SHOW_INFO_POPUP,
    HIDE_INFO_POPUP,
    SHOW_ADD_SCENE_POPUP,
    HIDE_ADD_SCENE_POPUP,
    GENERATE_HOMOGRAPHY,
    GENERATE_TPS,
    SELECTED_SHAPE,
    SELECTED_SHAPE_TO_DELETE,
    STORE_POINTS,
    UPDATE_COORDINATES,
    SET_DELETE_POINT,
    GENERATE_CUBIC,
    GENERATE_CV2
} from './constants';

export const showAddPoint = () => ({
    type: SHOW_ADD_POINT,
});

export const showAddTestPoint = () => ({
    type: SHOW_ADD_TEST_POINT,
});

export const hideAddTestPoint = () => ({
    type: HIDE_ADD_TEST_POINT,
});

export const hideAddPoint = () => ({
    type: HIDE_ADD_POINT,
});

export const showEditCameraPoint = () => ({
    type: SHOW_EDIT_CAMERA_POINT,
});

export const hideEditCameraPoint = () => ({
    type: HIDE_EDIT_CAMERA_POINT,
});

export const showMovePoint = () => ({
    type: SHOW_MOVE_POINT,
});

export const hideMovePoint = () => ({
    type: HIDE_MOVE_POINT,
});

export const showViewPointCoordinates = () => ({
    type: SHOW_VIEW_POINT_COORDINATES,
});

export const hideViewPointCoordinates = () => ({
    type: HIDE_VIEW_POINT_COORDINATES,
});

export const showSaveProject = () => ({
    type: SHOW_SAVE_PROJECT,
});

export const hideSaveProject = () => ({
    type: HIDE_SAVE_PROJECT,
});

export const showViewOptions = () => ({
    type: SHOW_VIEW_OPTIONS,
});

export const hideViewOptions = () => ({
    type: HIDE_VIEW_OPTIONS,
});

export const showEditScene = () => ({
    type: SHOW_EDIT_SCENE,
});

export const hideEditScene = () => ({
    type: HIDE_EDIT_SCENE,
});

export const showSceneLibrary = () => ({
    type: SHOW_SCENE_LIBRARY,
});

export const hideSceneLibrary = () => ({
    type: HIDE_SCENE_LIBRARY,
});

export const toggleAllCameras = () => ({
    type: TOGGLE_All_CAMERAS,
});

export const toggleInvertFloorplanColors = () => ({
    type: TOGGLE_INVERT_FLOORPLAN_COLORS,
});

export const toggleChangePointColors = () => ({
    type: TOGGLE_POINT_COLORS,
});

export const togglePlotGridLines = () => ({
    type: TOGGLE_PLOT_GRIDLINES,
});

export const SetImageMappingLoading = (bool) => ({
    type: IMAGE_MAPPING_LOADING, payload: bool
});

export const setAddSceneMultipleFlag = (bool) => ({
    type: SCENE_MULTIPLE_FLAG, payload: bool
});

export const showInfoPopup = () => ({
    type: SHOW_INFO_POPUP,
});

export const hideInfoPopup = () => ({
    type: HIDE_INFO_POPUP,
});

export const showAddScenePopup = () => ({
    type: SHOW_ADD_SCENE_POPUP,
});

export const hideAddScenePopup = () => ({
    type: HIDE_ADD_SCENE_POPUP,
});

export const setGenerateHomography = (bool) => ({
    type: GENERATE_HOMOGRAPHY, payload: bool
});

export const setGenerateTPS = (bool) => ({
    type: GENERATE_TPS, payload: bool
});

export const setGenerateCubic = (bool) => ({
    type: GENERATE_CUBIC, payload: bool
});

export const setGenerateCv2 = (bool) => ({
    type: GENERATE_CV2, payload: bool 
})

export const setSelectedShape = (value) => ({
    type: SELECTED_SHAPE, payload: value
});

export const setSelectedShapeToDelete = (value) => ({
    type: SELECTED_SHAPE_TO_DELETE, payload: value
});

export const setStorePoints = (value) => ({
    type: STORE_POINTS, payload: value
})

export const setUpdateCoordinates = data => ({
    type: UPDATE_COORDINATES, payload: data
})

export const setDeletePoint = bool => ({
    type: SET_DELETE_POINT, payload: bool
})



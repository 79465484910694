import { InputGroup, FormControl } from "react-bootstrap";
import GreenBtnLg from "./../../helpers/greenBtnLg/GreenBtnLg";
import BackBtn from "./../../helpers/backBtn/BackBtn";
import "./LoadFloorPlan.css";
import CustomImageUploader from "./../../CreateProject/CustomImageUploaderComponent";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCameraFiles,
  setCameraImageUploaded,
  setCameraPoints,
  setCameraPointsAvg,
  setCreateProjectData,
  setFloorplanPoints,
  setImageFiles,
  setImageUploaded,
} from "./../../../actions/imageData";
import { useHistory } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import * as Constants from "./../../../constants";
import {
  setImageUrls,
  setCameraImageUrls,
  setcurrentcameraNotes,
  setMaskShapes,
} from "./../../../actions/imageData";
import Loader from "react-loader-spinner";

function LoadFloorPlan() {
  const dispatch = useDispatch();
  const history = useHistory();
  const imageUrls = useSelector((state) => state.imageDataReducer.imageUrls);
  const project = useSelector((state) => state.imageDataReducer.project);
  const [imageFlag, setImageFlag] = useState(false);

  const [loading, setLoading] = useState({
    uploadStarted: false,
  });

  const [values, setJsonValues] = useState({
    client_name: project.client_name,
    building_name: project.building_name,
    project_name: project.project_name,
    cameraData: project.cameraData,
    floorplanImg: imageUrls,
  });
  function useForceUpdate() {
    return () => setImageFlag((imageFlag) => true);
    // update the state to force render
  }
  // call your hook here
  useForceUpdate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [singleImageError, setSingleImageError] = useState(false);
  const [invalidImageError, setInvalidImageError] = useState(false);

  const [clientValid, setClientValidMessage] = useState("");
  const [buildingValid, setBuildingValidMessage] = useState("");
  const [projectValid, setProjectValidMessage] = useState("");
  const [fileFormatValid, setfileFormatValidMessage] = useState("");

  const handleBack = () => {
    history.push("/");
  };
  const populateJsonData = (jsonData) => {
    setJsonValues({
      ...values,
      client_name: jsonData.client_name,
      building_name: jsonData.site_name,
      project_name: jsonData.project_name,
      cameraData: jsonData.cameraData,
    });
    setLoading({
      ...loading,
      uploadStarted: false,
    });
    // Onload validation
    if (!jsonData.client_name.match(/^[a-zA-Z0-9\s]+$/)) {
      setClientValidMessage(
        "Required input field is invalid. Please use alphanumeric values only."
      );
    } else {
      setClientValidMessage("");
    }

    if (!jsonData.site_name.match(/^[a-zA-Z0-9\s]+$/)) {
      setBuildingValidMessage(
        "Required input field is invalid. Please use alphanumeric values only."
      );
    } else {
      setBuildingValidMessage("");
    }

    if (
      !jsonData.project_name.match(/^[a-zA-Z0-9_\s-]+$/) ||
      jsonData.project_name.charAt(0) == " "
    ) {
      setProjectValidMessage(
        "Required input field is invalid. Please use alphanumeric values only."
      );
    } else {
      setProjectValidMessage("");
    }
  };

  const calculateAverage = (array) => {
    const sum = array.reduce(
      (acc, arr) => ({
        ...acc,
        xFactor: acc.xFactor + arr[0],
        yFactor: acc.yFactor + arr[1],
      }),
      {
        xFactor: 0,
        yFactor: 0,
      }
    );
    const avg = {
      ...sum,
      xFactor: sum.xFactor / array.length,
      yFactor: sum.yFactor / array.length,
    };
    dispatch(setCameraPointsAvg(avg));
  };

  const handleFiles = (files) => {
    let fileName = files[0].name;

    let ext = fileName.split(".")[1];
    if (ext === "zip") {
      setfileFormatValidMessage("");
      setLoading({
        ...loading,
        uploadStarted: true,
      });
      let headers = new Headers();
      // headers.append('Content-Type', 'application/json');
      headers.append("Content-Type", "multipart/form-data");
      // headers.append('Accept', 'application/json');
      // headers.append('Origin', Constants.REACT_APP_APIORIGIN);

      // var zip_file_path = Constants.REACT_APP_APIURL + "/loadZipFile"
      const zip_file_path = Constants.REACT_APP_API + "/unzip/loadZipFile";
      const formData = new FormData();
      formData.append("input", files[0]);
      fetch(zip_file_path, {
        mode: "cors",
        method: "POST",
        body: formData,
        // headers: headers
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          if (data?.jsonData) {
            let jsonData = JSON.parse(data.jsonData);
            const floorPlanFilename =
              jsonData.floorplan_filename.split("floorplan/")[1];
            const cameraFilename =
              jsonData.cameraData[0].filename.split("camera/")[1];
            populateJsonData(jsonData);
            // set Image
            setJsonValues({
              ...values,
              client_name: jsonData.client_name,
              building_name: jsonData.site_name,
              project_name: jsonData.project_name,
              cameraData: jsonData.cameraData,
              floorplanImg: data.floorPlanData,
            });

            calculateAverage(jsonData.cameraData[0].camera_points);
            dispatch(setCameraPoints(jsonData.cameraData[0].camera_points));
            dispatch(setFloorplanPoints(jsonData.cameraData[0].world_points));
            dispatch(setImageUrls(data.floorPlanData));
            dispatch(
              setImageUploaded({
                data: data.floorPlanData,
                name: floorPlanFilename,
              })
            );
            dispatch(
              setCameraImageUploaded({
                data: data.cameraData[0],
                name: cameraFilename,
              })
            );
            dispatch(setCameraImageUrls(data.cameraData));
            let sceneNotes = [];
            let masks = [];
            dispatch(setcurrentcameraNotes(sceneNotes));
            dispatch(setMaskShapes(masks));
          }
        });
    } else {
      setfileFormatValidMessage(
        "Please upload zip file only, it should contain data.json, floorplan folder and camera folder with corresponding images."
      );
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const str = event.target.value;
    if (target.name == "client_name") {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setClientValidMessage(
          "Required input field is invalid. Please use alphanumeric values only."
        );
      } else {
        setClientValidMessage("");
      }
    } else if (target.name == "building_name") {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setBuildingValidMessage(
          "Required input field is invalid. Please use alphanumeric values only."
        );
      } else {
        setBuildingValidMessage("");
      }
    } else {
      if (!str.match(/^[a-zA-Z0-9\s]+$/) || str.charAt(0) == " ") {
        setProjectValidMessage(
          "Required input field is invalid. Please use alphanumeric values only."
        );
      } else {
        setProjectValidMessage("");
      }
    }
    setJsonValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const onSubmit = () => {
    let count = 0;

    for (let item in values) {
      if (values[item] == null) {
        setErrorMessage("Please fill all the input fields.");
        break;
      } else {
        count += 1;
      }
    }
    if (
      (count === 3 || count === 5) &&
      !clientValid &&
      !buildingValid &&
      !projectValid
    ) {
      if (!imageUrls) {
        setErrorMessage("Please upload the floor plan image.");
      } else if (imageUrls && !imageUrls.length) {
        setErrorMessage("Please upload the floor plan image.");
      } else {
        setErrorMessage("");
        dispatch(setCreateProjectData(values));
        history.push("/loadAddScene");
      }
    }
  };

  const removeOldImage = () => {
    setJsonValues({
      ...values,
      floorplanImg: [],
    });
  };

  const handleDeleteImage = () => {
    setJsonValues({
      ...values,
      floorplanImg: [],
    });
    dispatch(setImageUrls([]));
    setImageFlag(false);
  };

  return (
    <div className="LoadFloorPlan">
      <div className="title">LOAD PROJECT</div>
      <p>
        Load a project and plot corresponding points in two views (typically a
        floor plan and camera view). One project corresponds to one floor of a
        building. For multi-story buildings, please create a project for each
        floor.
      </p>
      <div className="container">
        <h2 className="heading">Project File</h2>
        <p className="mb-0">
          Usually a .zip or a folder from your local directory
        </p>
        <ReactFileReader
          fileTypes={[".zip"]}
          multipleFiles={false}
          handleFiles={handleFiles}
        >
          <button type="button" className="chooseFileButton_loaddata">
            UPLOAD
          </button>
        </ReactFileReader>
        {loading.uploadStarted && (
          <Loader type="Oval" color="#00BFFF" height={30} width={30} />
        )}
        {fileFormatValid && (
          <span className="errorMessage">{fileFormatValid}</span>
        )}
        <h2 className="heading">Client Name</h2>
        <p>Usually refers to a company name</p>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Taubman"
            value={values.client_name}
            name="client_name"
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
            title="Three letter country code"
          />
        </InputGroup>
        {clientValid && <span className="errorMessage">{clientValid}</span>}
        <h2 className="heading">Site Name</h2>
        <p>Usually refers to the name of a specific location or building</p>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Beverly Center"
            name="building_name"
            value={values.building_name}
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
          />
        </InputGroup>
        {buildingValid && <span className="errorMessage">{buildingValid}</span>}
        <h2 className="heading">Project Title</h2>
        <p>Usually refers to a specific floor of the building</p>
        <InputGroup className="mb-3 create-project-input">
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            className="createProject-input"
            placeholder="Example: Floor 6"
            name="project_name"
            value={values.project_name}
            onChange={handleInputChange}
            maxlength="50"
            required
            pattern="[a-zA-Z0-9\s]+"
          />
        </InputGroup>
        {projectValid && <span className="errorMessage">{projectValid}</span>}
        <h2 className="heading">Upload Floor Plan</h2>
        <p className="description">
          The floor plan will be used for all scenes within the project (.png,
          .jpg, .jpeg)
        </p>
        <CustomImageUploader
          buttonText="UPLOAD"
          singleImage={true}
          singleCameraImage={false}
          setSingleImageError={setSingleImageError}
          setErrorMessage={setErrorMessage}
          setMultipleImageFlag={setImageFlag}
          withPreview={true}
          removeOldImage={removeOldImage}
          setInvalidImageError={setInvalidImageError}
        />

        {(imageUrls.length > 0 && values.floorplanImg.length) || imageFlag ? (
          <div className="fileUploader ">
            <div className="fileContainer">
              <div className="uploadPicturesWrapper">
                <div className="uploadPicturesWrapper_inner_div">
                  <div className="uploadPictureContainer">
                    <div className="deleteImage" onClick={handleDeleteImage}>
                      X
                    </div>
                    <img
                      src={imageUrls}
                      className="uploadPicture"
                      alt="preview"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {singleImageError && (
          <p className="errorMessage">
            Invalid Request. Remove the old image and then try to add new image.
          </p>
        )}
        {invalidImageError && (
          <p className="errorMessage">
            Please upload the valid image. (.png .jpg .jpeg) images only
            allowed.
          </p>
        )}
        <div className="navigation-buttons">
          <BackBtn label="BACK" customClickEvent={handleBack} />
          <GreenBtnLg label="NEXT" customClickEvent={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default LoadFloorPlan;

import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import "./ViewPointCoordinates.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFloorplanmarkers,
  setCameramarkers,
  setloadCameraUrl,
  setAdjustViewPosition,
  setCameraPoints,
  setFloorplanPoints,
} from "./../../actions/imageData";
import ErrorIcon from "./../../assets/icons/icon-alert.svg";
import { setUpdateCoordinates } from "../../actions/imageOptions";

function ViewPointCoordinates(props) {
  const dispatch = useDispatch();
  const homographyData = useSelector(
    (state) => state.imageDataReducer.homography
  );

  // Markers[currentCameraIndex]
  let currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const [errorMessage, setErrorMessage] = useState(null);
  if (currentCameraIndex === undefined) currentCameraIndex = 0;
  const floorplanmarkers = useSelector(
    (state) => state.imageDataReducer.floorplanmarkers
  );
  const cameramarkers = useSelector(
    (state) => state.imageDataReducer.cameramarkers
  );
  const [floorplanmarkerstate, setFloorPlanData] = useState(floorplanmarkers);
  // STATES FOR FLOORPLAN AND CAMERA SCENE POINTS
  const floorplanPoints = useSelector(
    (state) => state.imageDataReducer.floorplanPoints
  );
  const cameraPoints = useSelector(
    (state) => state.imageDataReducer.cameraPoints
  );

  const floorPlanImage = useSelector(
    (state) => state.imageDataReducer.imageUrls
  );
  const cameraImageUrls = useSelector(
    (state) => state.imageDataReducer.cameraImageUrls
  );
  const cameraSceneDimensions = useSelector(
    state => state.imageDataReducer.cameraSceneDimensions
  )
  const floorplanDimensions = useSelector(
    state => state.imageDataReducer.floorplanDimensions
  )

  let cameraImg = cameraImageUrls[currentCameraIndex];
  const scene_name = cameraImg.split(";")[1].slice(5);

  var SceneImage = new Image();
  SceneImage.src = cameraImg;

  var FloorImg = new Image();
  FloorImg.src = floorPlanImage;

  let camera_bounds_x = SceneImage.width;
  let camera_bounds_y = SceneImage.height;

  let world_bounds_x = FloorImg.width;
  let world_bounds_y = FloorImg.height;

  const floorPlanScales = {
    xFactor: FloorImg.width / floorplanDimensions.width,
    yFactor: FloorImg.height / floorplanDimensions.height
  }
  const cameraSceneScales = {
    xFactor: SceneImage.width / cameraSceneDimensions.width,
    yFactor: SceneImage.height / cameraSceneDimensions.height
  }

  useEffect(() => {
    setFloorPlanData(floorplanmarkers);
  }, [floorplanmarkers, cameramarkers]);

  const handleInput = (e, point_type, index, key) => {
    dispatch(setAdjustViewPosition(false));
    let inputValue = e.target.value;

    if (point_type === "world_points") {
      if (
        (key === "x" && (inputValue === "" || inputValue <= world_bounds_x)) ||
        (key === "y" && (inputValue === "" || inputValue <= world_bounds_y))
      ) {
        const relativeKey = `relative${key.toUpperCase()}`;
        const absoluteCoordinate = floorplanPoints[index][key];
        const deltaDistance = (Number(inputValue) - floorplanPoints[index][relativeKey]) / (key === 'x'? floorPlanScales.xFactor: floorPlanScales.yFactor)
        floorplanPoints[index][key] = key === 'x'? (absoluteCoordinate + deltaDistance) : (absoluteCoordinate - deltaDistance)
        floorplanPoints[index][relativeKey] = Number(inputValue);

        dispatch(setFloorplanPoints([...floorplanPoints]));
        setErrorMessage(null);
      } else {
        setErrorMessage("Modified Floor plan value is not in boundary!");
        return;
      }
    } else if (point_type === "camera_points") {
      if (
        (key === "x" && (inputValue === "" || inputValue <= camera_bounds_x)) ||
        (key === "y" && (inputValue === "" || inputValue <= camera_bounds_y))
      ) {
        const relativeKey = `relative${key.toUpperCase()}`;
        const absoluteCoordinate = cameraPoints[index][key];
        const deltaDistance = (Number(inputValue) - cameraPoints[index][relativeKey]) / (key === 'x'? cameraSceneScales.xFactor: cameraSceneScales.yFactor)
        cameraPoints[index][key] = absoluteCoordinate + deltaDistance;
        cameraPoints[index][relativeKey] = Number(inputValue);
        dispatch(setCameraPoints([...cameraPoints]));
        setErrorMessage(null);
      } else {
        setErrorMessage("Modified camera scene value is not in boundary!");
        return;
      }
    }
  };

  const handleUpdate = () => {
    let errorFlag = 0;
    if (!cameraPoints && !floorplanmarkers) return;
    cameraPoints.forEach((element) => {
      if (element.x === "" || element.y === "") {
        setErrorMessage(
          "Some colums are empty. Please enter the valid value for Co-ordinates."
        );
        errorFlag = 1;
      }
    });

    floorplanPoints.forEach((element) => {
      if (element.x === "" || element.y === "") {
        setErrorMessage(
          "Some colums are empty. Please enter the valid value for Co-ordinates."
        );
        errorFlag = 1;
      }
    });

    if (!errorFlag) {
      dispatch(setAdjustViewPosition(false));
      dispatch(setUpdateCoordinates(true));
      props.onHide();
    }
  };
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          POINT COORDINATES
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="outer-box">
          <div className="inner-box">
            <h2>FLOOR PLAN</h2>
            <table width="100%">
              <colgroup>
                <col span="1" width="100px" />
                <col span="2" />
                <col span="1" width="100px" />
              </colgroup>
              <tr>
                <th>POINT ID</th>
                <th>X</th>
                <th>Y</th>
                <th>Z</th>
              </tr>
              {floorplanPoints &&
                floorplanPoints.map((coordinates, index) => {
                  return (
                    <tr key={index}>
                      <td>{(index < 10 ? "0" : "") + coordinates.id}</td>
                      <td>
                        <input
                          className="viewCoordInput"
                          value={coordinates["relativeX"]}
                          onChange={(e) =>
                            handleInput(e, "world_points", index, "x")
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="viewCoordInput"
                          value={coordinates["relativeY"]}
                          onChange={(e) =>
                            handleInput(e, "world_points", index, "y")
                          }
                        />
                      </td>
                      <td>1</td>
                    </tr>
                  );
                })}
            </table>
          </div>
          <div className="inner-box">
            <h2>SCENE</h2>
            <table width="100%">
              <colgroup>
                <col span="1" width="100px" />
              </colgroup>
              <tr>
                <th>
                  <span>POINT ID</span>
                </th>
                <th>X</th>
                <th>Y</th>
              </tr>
              {cameraPoints &&
                cameraPoints.map((coordinates, index) => {
                  return (
                    <tr key={index}>
                      <td>{(index < 10 ? "0" : "") + index}</td>
                      <td>
                        <input
                          className="viewCoordInput"
                          value={coordinates["relativeX"]}
                          onChange={(e) =>
                            handleInput(e, "camera_points", index, "x")
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="viewCoordInput"
                          value={coordinates["relativeY"]}
                          onChange={(e) =>
                            handleInput(e, "camera_points", index, "y")
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
        {errorMessage && (
          <p className="errorMessage-pointCoordinates">
            <img src={ErrorIcon} alt="Error-icon" />
            {errorMessage}
          </p>
        )}
        {homographyData &&
          homographyData.length !== 0 &&
          homographyData !== undefined &&
          homographyData[currentCameraIndex] !== undefined && (
            <h3 className="error-h3">
              <img src={ErrorIcon} alt="Error-icon" />
              <div>
                Projection Error: {homographyData[currentCameraIndex].rmse}
              </div>
            </h3>
          )}
        {!errorMessage && (
          <div className="update-btn">
            <Button variant="primary" onClick={handleUpdate}>
              UPDATE
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ViewPointCoordinates;

ViewPointCoordinates.propTypes = {
  onHide: PropTypes.func,
};

import GreenBtnLg from "./../../helpers/greenBtnLg/GreenBtnLg";
import BackBtn from "./../../helpers/backBtn/BackBtn";
import "./LoadAddScene.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showImageButtonOptions } from "./../../../actions/index";
import {
  showSceneLibrary,
  setAddSceneMultipleFlag,
} from "./../../../actions/imageOptions";
import CustomImageUploader from "./../../CreateProject/CustomImageUploaderComponent";
import { Fragment } from "react";
import { setImageUrls, setCameraImageUrls } from "./../../../actions/imageData";
import CloseIcon from "./../../../assets/icons/cancel.webp";

function LoadAddScene() {
  const imageUrls = useSelector((state) => state.imageDataReducer.imageUrls);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [maxImageError, setMaxImageError] = useState(false);
  const [singleImageError, setSingleImageError] = useState(false);
  const cameraImageUrls = useSelector(
    (state) => state.imageDataReducer.cameraImageUrls
  );
  const sceneMultipleFlag = useSelector(
    (state) => state.imageOptionsReducer.sceneMultipleFlag
  );

  const [values, setJsonValues] = useState({
    cameraImg: cameraImageUrls,
  });

  const [multipleImageFlag, setMultipleImageFlag] = useState(false);
  function useForceUpdate() {
    return () => setMultipleImageFlag((multipleImageFlag) => false); // update the state to force render
  }

  const handleBack = () => {
    history.push("/loadFloorPlan");
  };

  const removeOldImage = () => {
    setJsonValues({
      ...values,
      cameraImg: [],
    });
  };
  const handleDeleteImage = (e) => {
    let index = e.target.getAttribute("data");
    let cameraImageUrlsTemp = cameraImageUrls;
    cameraImageUrlsTemp.splice(index, 1);
    setJsonValues({
      ...values,
      cameraImg: cameraImageUrlsTemp,
    });
    if (cameraImageUrlsTemp.length < 60) {
      setMaxImageError(false);
    }
    dispatch(setCameraImageUrls(cameraImageUrlsTemp));
  };

  const addSceneMultipleFlag = (flag) => {
    dispatch(setAddSceneMultipleFlag(flag));
  };

  const handleFinsh = () => {
    if (!cameraImageUrls.length) {
      setErrorMessage("Please upload the camera view image.");
    } else {
      setErrorMessage("");
      history.push("/imageMapping");
      dispatch(showImageButtonOptions());  
      dispatch(showSceneLibrary());
    }
  };
  useForceUpdate();
  return (
    <div className="LoadAddScene">
      <div className="title">LOAD PROJECT</div>
      <div className="container">
        <h2 className="heading">Add Scene</h2>
        <p>
          A scene consists of two spaces, the floor plan and a still from a
          video camera. Scene will be titled using the <br />
          filename of the camera image you upload. You must add at least one
          scene to create a project.
          <br />
          (.png .jpg .jpeg)
        </p>
        <div className="btn-list">
          <CustomImageUploader
            buttonText="UPLOAD SINGLE IMAGE"
            singleImage={true}
            singleCameraImage={true}
            setSingleImageError={setSingleImageError}
            setErrorMessage={setErrorMessage}
            removeOldImage={removeOldImage}
            setMultipleImageFlag={setMultipleImageFlag}
            withPreview={false}
            cameraImageUrls={cameraImageUrls}
            setAddSceneMultipleFlag={addSceneMultipleFlag}
            sceneMultipleFlag={sceneMultipleFlag}
          />
          <CustomImageUploader
            buttonText="UPLOAD BULK IMAGE"
            singleImage={false}
            singleCameraImage={false}
            setMaxImageError={setMaxImageError}
            setErrorMessage={setErrorMessage}
            removeOldImage={removeOldImage}
            setMultipleImageFlag={setMultipleImageFlag}
            withPreview={false}
            cameraImageUrls={cameraImageUrls}
            setAddSceneMultipleFlag={addSceneMultipleFlag}
            sceneMultipleFlag={sceneMultipleFlag}
          />
        </div>

        {(cameraImageUrls.length > 0 && values.cameraImg.length > 0) ||
        multipleImageFlag ? (
          <div className="fileUploader ">
            <div className="fileContainer">
              <div className="uploadPicturesWrapper">
                <div className="uploadPicturesWrapper_inner_div">
                  {cameraImageUrls.length > 10
                    ? cameraImageUrls.map((object, i) => {
                        let name = object.split(";")[1].slice(5);
                        let temp = name.split(".");
                        name = temp[0].slice(0, 8) + "." + temp[1];
                        return (
                          <div className="image-pill" key={i}>
                            <div className="image-pill-text">{name}</div>
                            <img
                              src={CloseIcon}
                              alt="close-Icon"
                              data={i}
                              onClick={handleDeleteImage}
                            />
                          </div>
                        );
                      })
                    : cameraImageUrls.map((object, i) => (
                        <div className="uploadPictureContainer" key={i}>
                          <div
                            className="deleteImage"
                            data={i}
                            onClick={handleDeleteImage}
                          >
                            X
                          </div>
                          <img
                            src={object}
                            className="uploadPicture"
                            alt="preview"
                          />
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {maxImageError && (
          <p className="errorMessage">
            Maximum limit of 60 images is reached for bulk upload. Try in next
            batch upload.
          </p>
        )}
        {singleImageError && (
          <p className="errorMessage">
            Invalid Request. Remove the old image and then try to add new image.
          </p>
        )}
        <div className="navigation-buttons">
          <BackBtn label="BACK" customClickEvent={handleBack} />
          <GreenBtnLg label="FINISH" customClickEvent={handleFinsh} />
        </div>
      </div>
    </div>
  );
}

export default LoadAddScene;
  
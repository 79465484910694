import React from 'react';
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';
import Home from './components/Home/Home.js';
import AddScene from './components/AddScene/AddScene.js';
import ImageMapping from './components/ImageMapping/ImageMapping.js';
import CreateProject from './components/CreateProject/CreateProject.js';
import LoadFloorPlan from './components/LoadProject/LoadFloorPlan/LoadFloorPlan.js';
import LoadAddScene from './components/LoadProject/LoadAddScene/LoadAddScene.js';
import { MemoryRouter, Switch, Route } from "react-router-dom";
import './App.css';

function App() {
  // Enable navigation prompt
  document.body.onmousedown  = (e) => {
    if(e.button === 1) return false;
  }
  window.onbeforeunload = function () {
    return true;
  };
  return (
    <MemoryRouter>
      <div className="App">
        <Header />
        <div className="gradient">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/createProject">
              <CreateProject />
            </Route>
            <Route path="/addScene">
              <AddScene />
            </Route>
            <Route path="/imageMapping">
              <ImageMapping />
            </Route>
            <Route path="/loadFloorPlan">
              <LoadFloorPlan />
            </Route>
            <Route path="/loadAddScene">
              <LoadAddScene />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </MemoryRouter>
  );
}

export default App;

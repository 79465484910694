import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, Fragment } from "react";
import { setHomography } from "./../../actions/imageData";
import {
  setloadCameraUrl,
  setCountHomographyCall,
} from "./../../actions/imageData";
import "./GenerateHomography.css";

import * as Constants from "../../constants";

function GenerateHomography(props) {
  const dispatch = useDispatch();
  const countHomographyCall = useSelector(
    (state) => state.imageDataReducer.countHomographyCall
  );
  const floorplanmarkers = useSelector(
    (state) => state.imageDataReducer.floorplanmarkers
  );
  const cameramarkers = useSelector(
    (state) => state.imageDataReducer.cameramarkers
  );
  const generate = useSelector(
    (state) => state.imageOptionsReducer.generateHomography
  );
  const generate_tps = useSelector(
    (state) => state.imageOptionsReducer.generateTPS
  );
  const generate_cubic = useSelector(
    (state) => state.imageOptionsReducer.generateCubic
  );
  const generate_cv2 = useSelector(
    (state) => state.imageOptionsReducer.generateCv2
  );

  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [homographyResult, setHomographyResult] = useState(false);
  const floorPlanImage = useSelector(
    (state) => state.imageDataReducer.imageUrls
  );
  // STATES FOR FLOORPLAN AND CAMERA POINTS
  const cameraScenePoints = useSelector(
    (state) => state.imageDataReducer.cameraPoints
  );
  const floorplanPoints = useSelector(
    (state) => state.imageDataReducer.floorplanPoints
  );
  const cameraImageUrls = useSelector(
    (state) => state.imageDataReducer.cameraImageUrls
  );
  let currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const homography = useSelector((state) => state.imageDataReducer.homography);

  if (currentCameraIndex === undefined) currentCameraIndex = 0;

  const [error, setError] = useState("");

  const fetchGenerateHomographyAPI = () => {
    let floorPoints = floorplanPoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraPoints = cameraScenePoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraImg = cameraImageUrls[currentCameraIndex];
    const scene_name = cameraImg.split(";")[1].slice(5);

    var SceneImage = new Image();
    SceneImage.src = cameraImg;

    var FloorImg = new Image();
    FloorImg.src = floorPlanImage;

    // NEED TO VERIFY IF THE IMAGE WIDTH AND HEIGHT MATCH THE MAX X AND Y COORDINATES ON THE CANVAS.
    let data = {
      cameraData: {
        world_points: floorPoints,
        camera_points: cameraPoints,
        scene_name: scene_name,
      },

      // Change these bounds from static to real values
      camera_bounds: [SceneImage.width, SceneImage.height],
      world_bounds: [FloorImg.width, FloorImg.height],
    };
    // fetch(Constants.REACT_APP_HOMOGRAPHY_SERVICE, {
    //   mode: "cors",
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    fetch(`${Constants.REACT_APP_API}/projection/homography`, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setHomographyResult(true);
        // dispatch(generateHomography(false));
        if (data.detail) {
          setError(data.detail);
        } else {
          if (homography && homography !== undefined) {
            const homographyData = [...homography];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] =
              data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
            let temp = countHomographyCall;
            if (temp[currentCameraIndex] >= 0) {
              temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
            } else {
              temp[currentCameraIndex] = 0;
            }
            dispatch(setCountHomographyCall(temp));
          } else {
            let homographyData = [];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] =
              data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setError(error.message);
      });
    // }
  };

  const getProjectionName = () => {
    if (generate) return "Homography";
    else if (generate_tps) return "TPS";
    else if (generate_cubic) return "Cubic Projection";
    else if (generate_cv2) return "Cv2 Calibration";
  };

  const generateCubicProjection = () => {
    // if(floorplanmarkers && cameramarkers && floorplanmarkers[currentCameraIndex] !== undefined  && cameramarkers[currentCameraIndex] !== undefined){
    // let floorPoints = floorplanmarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
    // let cameraPoints = cameramarkers[currentCameraIndex].map(item=>[item.XPos, item.YPos]);
    let floorPoints = floorplanPoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraPoints = cameraScenePoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraImg = cameraImageUrls[currentCameraIndex];
    const scene_name = cameraImg.split(";")[1].slice(5);
    var SceneImage = new Image();
    SceneImage.src = cameraImg;

    var FloorImg = new Image();
    FloorImg.src = floorPlanImage;

    let data = {
      cameraData: {
        world_points: floorPoints,
        camera_points: cameraPoints,
        scene_name: scene_name,
      },

      // Change these bounds from static to real values
      camera_bounds: [SceneImage.width, SceneImage.height],
      world_bounds: [FloorImg.width, FloorImg.height],
    };
    fetch(Constants.REACT_APP_API + "/projection/cubic/", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setHomographyResult(true);
        // dispatch(generateTPS(false));
        if (data.detail) {
          setError(data.detail);
        } else {
          if (homography && homography !== undefined) {
            const homographyData = [...homography];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
            let temp = countHomographyCall;
            if (temp[currentCameraIndex] >= 0) {
              temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
            } else {
              temp[currentCameraIndex] = 0;
            }
            dispatch(setCountHomographyCall(temp));
          } else {
            let homographyData = [];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
          }
        }
      })
      .catch((error) => {
        setError(error.message);
      });
    // }
  };

  const generateCv2Projection = () => {
    let floorPoints = floorplanPoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraPoints = cameraScenePoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraImg = cameraImageUrls[currentCameraIndex];
    const scene_name = cameraImg.split(";")[1].slice(5);
    var SceneImage = new Image();
    SceneImage.src = cameraImg;

    var FloorImg = new Image();
    FloorImg.src = floorPlanImage;

    let data = {
      cameraData: {
        world_points: floorPoints,
        camera_points: cameraPoints,
        scene_name: scene_name,
      },

      // Change these bounds from static to real values
      camera_bounds: [SceneImage.width, SceneImage.height],
      world_bounds: [FloorImg.width, FloorImg.height],
    };
    fetch(Constants.REACT_APP_API + "/projection/cv2calibrate/", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setHomographyResult(true);
        // dispatch(generateTPS(false));
        if (data.detail) {
          setError(data.detail);
        } else {
          if (homography && homography !== undefined) {
            const homographyData = [...homography];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
            let temp = countHomographyCall;
            if (temp[currentCameraIndex] >= 0) {
              temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
            } else {
              temp[currentCameraIndex] = 0;
            }
            dispatch(setCountHomographyCall(temp));
          } else {
            let homographyData = [];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
          }
        }
      })
      .catch((error) => {
        setError(error.message);
      });
    // }
  };

  const fetchGenerateTPSAPI = () => {
    let floorPoints = floorplanPoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraPoints = cameraScenePoints.map((point) => [
      point.relativeX,
      point.relativeY,
    ]);
    let cameraImg = cameraImageUrls[currentCameraIndex];
    const scene_name = cameraImg.split(";")[1].slice(5);
    var SceneImage = new Image();
    SceneImage.src = cameraImg;

    var FloorImg = new Image();
    FloorImg.src = floorPlanImage;

    let data = {
      cameraData: {
        world_points: floorPoints,
        camera_points: cameraPoints,
        scene_name: scene_name,
      },

      // Change these bounds from static to real values
      camera_bounds: [SceneImage.width, SceneImage.height],
      world_bounds: [FloorImg.width, FloorImg.height],
    };
    fetch(Constants.REACT_APP_API + "/projection/tps/", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setHomographyResult(true);
        // dispatch(generateTPS(false));
        if (data.detail) {
          setError(data.detail);
        } else {
          if (homography && homography !== undefined) {
            const homographyData = [...homography];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
            let temp = countHomographyCall;
            if (temp[currentCameraIndex] >= 0) {
              temp[currentCameraIndex] = temp[currentCameraIndex] + 1;
            } else {
              temp[currentCameraIndex] = 0;
            }
            dispatch(setCountHomographyCall(temp));
          } else {
            let homographyData = [];
            homographyData[currentCameraIndex] = data;
            homographyData[currentCameraIndex]["projection_type"] = data.projection_type;
            dispatch(setHomography(homographyData));
            dispatch(setloadCameraUrl(true));
          }
        }
      })
      .catch((error) => {
        setError(error.message);
      });
    // }
  };

  useEffect(() => {
    setError("");
    setHomographyResult(false);
    if (generate) {
      fetchGenerateHomographyAPI();
    } else if (generate_tps) {
      fetchGenerateTPSAPI();
    } else if (generate_cubic) {
      generateCubicProjection();
    } else if (generate_cv2) {
      generateCv2Projection();
    }
  }, [
    generate,
    floorplanmarkers,
    cameramarkers,
    generate_tps,
    generate_cubic,
    generate_cv2,
  ]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* {flag ? ( */}
        <Modal.Body className="generateHomographyPopup">
          {error ? (
            <Fragment>
              <p className="projection-error">{error}</p>
              <div className="BTN-blue-overlay" onClick={props.onHide}>
                CLOSE
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {homographyResult ? (
                <Fragment>
                  <p className={error ? "projection-error" : ""}>
                    {error
                      ? error
                      : (currentCameraIndex === 0 &&
                          countHomographyCall[currentCameraIndex] > 0) ||
                        (currentCameraIndex !== 0 &&
                          countHomographyCall[currentCameraIndex] > 1)
                      ? `${getProjectionName()} has been created!`
                      : `${getProjectionName()} has been created!`}
                  </p>
                  <div className="BTN-blue-overlay" onClick={props.onHide}>
                    CLOSE
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <p>{getProjectionName()} Generation in Progress</p>
                  <div className="homography-loader"></div>
                </Fragment>
              )}
            </Fragment>
          )}
        </Modal.Body>
      {/* ) } */}
    </Modal>
  );
}

export default GenerateHomography;
